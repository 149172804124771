import React, {useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Alert, Snackbar} from "@mui/material";

const ComponentIdentifier = ({evt, isVisible}) => {
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    if (!evt || !isVisible) {
        return (<></>);
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCopiedToClipboard(false);
    };
    let closestDataComponent = '';
    try {
         closestDataComponent =  evt.target.closest('[data-component]').getAttribute('data-component');
    }catch(error){
        // just ignore
    }

    return (
        <>
            <CopyToClipboard text={closestDataComponent}
                             onCopy={() => setCopiedToClipboard(true)}>
                <p style={{color: 'red'}}>{closestDataComponent}</p>
            </CopyToClipboard>
            <Snackbar
            style={{marginTop: '20px'}}
            open={copiedToClipboard}
            autoHideDuration={2500}
            onClose={handleSnackbarClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={`must-continue-snackbar-alert`}
        >
            <Alert severity="info">
                "{closestDataComponent}" copied to clipboard
            </Alert>
        </Snackbar>
        </>
    );
};

export default ComponentIdentifier;
