import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {assignmentsEntity, assignmentNoteEntity, assignmentTaskEntity} from "../../schemas";
import {baseAPI} from "../../services/baseAPI";

const assignmentAdapter = createEntityAdapter();

export const assignCase = createAsyncThunk("assignments/assignCase", async ({formData}) => {
        const results = await baseAPI.post(`/api/client_case_assignments`, formData, [{
            name: 'content-type',
            value: 'application/ld+json'
        }]);

        const normalized = normalize(results.data, assignmentsEntity);
        return normalized.entities;
    }
);

export const checkExpiredAssignment = createAsyncThunk("assignments/checkExpiredAssignment", async ({dossierId}) => {
    const results = await baseAPI.get(`/api/client_cases/${dossierId}/check_client_assignment`, [], [{
        name: 'content-type',
        value: 'application/ld+json'
    }]);
    return results.data;
});

export const addNoteToAssignment = createAsyncThunk("assignments/addNoteToAssignment", async ({formData}) => {
    const results = await baseAPI.post(`/api/client_case_assignment_notes`, formData, [{
        name: 'content-type',
        value: 'application/ld+json'
    }]);

    const normalized = normalize(results.data, assignmentNoteEntity);
    return normalized.entities;
});

export const updateAssignmentNote = createAsyncThunk("assignments/updateAssignmentNote", async ({id, formData}) => {
    const results = await baseAPI.patch(`/api/client_case_assignment_notes/${id}`, formData, [{
        name: 'content-type',
        value: 'application/ld+json'
    }]);

    const normalized = normalize(results.data, assignmentNoteEntity);
    return normalized.entities;

});

export const deleteAssignmentNote = createAsyncThunk("assignments/deleteAssignmentNote", async ({id, dossierId, assignmentId}) => {
    await baseAPI.delete(`/api/client_case_assignment_notes/${id}`);
    return {id, dossierId, assignmentId};
});


export const addTaskToAssignment = createAsyncThunk("assignments/addTaskToAssignment", async ({formData, dossierId, assignmentId}) => {
    const results = await baseAPI.post(`/api/client_case_assignment_tasks`, formData, [{
        name: 'content-type',
        value: 'application/ld+json'
    }]);
    const normalized = normalize(results.data, assignmentTaskEntity);
    return {tasks: normalized.entities, dossierId, assignmentId};
});

export const updateAssignmentTask = createAsyncThunk("assignments/updateAssignmentTask", async ({id, formData, dossierId, assignmentId}) => {
    const results = await baseAPI.patch(`/api/client_case_assignment_tasks/${id}`, formData, [{
        name: 'content-type',
        value: 'application/ld+json'
    }]);
    const normalized = normalize(results.data, assignmentTaskEntity);
    return {tasks: normalized.entities, taskId: id, dossierId, assignmentId};
});

export const deleteAssignmentTask = createAsyncThunk("assignments/deleteAssignmentTask", async ({id, dossierId, assignmentId}) => {
    await baseAPI.delete(`/api/client_case_assignment_tasks/${id}`);
    return {id, dossierId, assignmentId};
});


export const updateAssignment = createAsyncThunk("assignments/updateAssignment", async ({id, formData, completion = null}) => {
        const results = await baseAPI.patch(`/api/client_case_assignments/${id}`, formData, [{
            name: 'content-type',
            value: 'application/ld+json'
        }]);
        if (null !== completion) {
            return completion;
        }
        const normalized = normalize(results.data, assignmentsEntity);
        return normalized.entities;
    }
);

const assignmentSlice = createSlice({
    name: 'assignments',
    initialState: assignmentAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder ) => {
        builder.addCase(updateAssignment.fulfilled, (state, {payload}) => {
            /**
             * @todo
             */
            console.log('[updateAssignment.fulfilled]', payload);
        })
    }
});

export default assignmentSlice.reducer

// Rename the exports for readability in component usage
export const {
    selectAll: selectAllAssignees,
} = assignmentAdapter.getSelectors(state => state.assignments);
