import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import screenShareAPI from "../../services/screenShareAPI";
import {screenSharesEntity} from "../../schemas";

const screenAdapter = createEntityAdapter();


export const fetchScreenShares = createAsyncThunk("screenShares/fetchScreenShares", async ({screenPage = null}) => {
    const results = await screenShareAPI.list(screenPage);
    const normalized = normalize(results.data['hydra:member'], [screenSharesEntity]);
    return {entities: normalized.entities, total: results.data['hydra:totalItems']};
});

export const updateScreenShare = createAsyncThunk("screenShares/updateScreenShare", async ({id, formData}) => {
    const results = await screenShareAPI.patch(id, formData);
    return results.data;
});

export const getScreenShare = createAsyncThunk("screenShares/getScreenShare", async (id) => {
    const results = await screenShareAPI.get(id);
    const normalized = normalize(results.data, screenSharesEntity);
    return normalized.entities;
});

export const getAdminScreenShareAutocomplete = createAsyncThunk("screenShares/getAdminScreenShareAutocomplete", async () => {
    const results = await screenShareAPI.getAdminScreenShareAutocomplete();
    const normalized = normalize(results.data, screenSharesEntity);
    return normalized.entities;
});


const screenShareSlice = createSlice({
    name: 'screenShares',
    initialState: screenAdapter.getInitialState(),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchScreenShares.fulfilled, (state, {payload}) => {
            state.ids = [];
            state.entities = {};
            screenAdapter.upsertMany(state, payload?.entities?.screenShares ?? []);
            state.total = payload?.total;
        })
    }
});

export default screenShareSlice.reducer;

// Rename the exports for readability in component usage
export const {
    selectById: getScreenById,
    selectAll: getAllScreenShares,
} = screenAdapter.getSelectors(state => state.screenShares);



