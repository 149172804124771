export const ADMIN = Object.freeze({
    TABS: {
        ASSISTANCE: 'assistance',
        REFERRAL: 'referral',
    }
});

export const VIEW = Object.freeze({
    LIST: {
        PLACEHOLDER: {
            ADMIN_NO_INCOMING_SCREENS: 'Er worden geen schermen gedeeld.',
            ADMIN_NO_DOSSIERS: 'Er zijn geen juridische dossiers.',
            ADMIN_NO_DOSSIERS_FOR_CATEGORY: 'Er zijn geen juridische dossiers met de categorie '
        }
    }
});

export const RHV = Object.freeze({
    SIMPLE_MAP: {
        "bestuursrecht": [], // category only
        "sociaalzekerheidsrecht": ["Overheid", "Uitkering", "Toeslag"],
        "privaatrecht-algemeen": [], // category only
        "verbintenissenrecht": ["Consumentenkoop"],
        "huurrecht": ["Wonen"],
        "arbeidsrecht": ["Arbeid"],
        "goederenrecht": ["Consumentenkoop"],
        "AVG" : ["PRIVACY"],
        "persoon-en-familierecht-voorwaardelijk": ["Persoon/familie"],
        "erfrecht": ["Persoon/familie"],
        "slachtofferzaken": ["Politie"],
        "jeugdstrafrecht": ["Boete"],
        "letselschade": ["INJURY"],
        "strafrecht": ["Politie"],
        "strafpiket": ["Boete"],
    },
    AREA_TO_CATEGORY: {
        "bestuursrecht": {type: 'any', categories: ["ALLOWANCE"]},
        "sociaalzekerheidsrecht": {
            type: 'all',
            categories: ["GOVERNMENT", "BENEFITS", "ALLOWANCE"]
        },
        "privaatrecht-algemeen": {type: 'any', categories: ["PURCHASE", "LIVING", "EMPLOYMENT", "PRIVACY"]},
        "verbintenissenrecht": {type: 'all', categories: ["PURCHASE"]},
        "huurrecht": {type: 'all', categories: ["LIVING"]},
        "arbeidsrecht": {type: 'all', categories: ["EMPLOYMENT"]},
        "goederenrecht": {type: 'all', categories: ["PURCHASE"]},
        "AVG" :  {type: 'all', categories: ["PRIVACY"]},
        "persoon-en-familierecht-voorwaardelijk": {type: 'all', categories: ["FAMILY"]},
        "erfrecht": {type: 'all', categories: ["FAMILY"]},
        "slachtofferzaken": {type: 'all', categories: ["POLICE"]},
        "jeugdstrafrecht": {type: 'all', categories: ["FINE"]},
        "letselschade": {type: 'all', categories: ["INJURY"]},
        "strafrecht": {type: 'all', categories: ["POLICE"]},
        "strafpiket": {type: 'all', categories: ["FINE"]},
    },
    TRANSLATE_AREAS_OF_LAW: {
        "Arbeidsrecht": "arbeidsrecht",
        "Sociaal Zekerheidsrecht": "sociaalzekerheidsrecht",
        "Personen- en Familierecht": "persoon-en-familierecht-voorwaardelijk",
        "Jeugd Strafrecht": "jeugdstrafrecht",
        "Strafrecht": "strafrecht",
        "Int. Kinder Ontvoeringszaken": null,
        "Huurrecht": "huurrecht",
        "Slachtofferzaken": "slachtofferzaken",
        "Vreemdelingenrecht": null,
        "Asielrecht": null,
        "Bijzonder curator BW1:212": null,
        "Psychiatrisch Patiëntenrecht": null,
        "Bijzonder curator BW1:250": null
    },
    ENSURE_ALL: {
        "sociaalzekerheidsrecht": "bestuursrecht",
        "verbintenissenrecht": "privaatrecht-algemeen",
        "huurrecht": "privaatrecht-algemeen",
        "arbeidsrecht": "privaatrecht-algemeen",
        "goederenrecht": "privaatrecht-algemeen"
    },
    ENSURE_ANY: {
        "bestuursrecht": ["sociaalzekerheidsrecht"],
        "privaatrecht-algemeen": ["verbintenissenrecht", "huurrecht", "arbeidsrecht", "goederenrecht"],
    }
})

export const DOSSIER = Object.freeze({
    TYPE: {
        CLIENT: 'client_dossier',
        ADMIN: 'admin_dossier',
        ASSIGNEE_ADMIN: 'assignee_admin',
        ASSIGNEE_WORKER: 'assignee_worker',
        ASSIGNEE: ['assignee_admin', 'assignee_worker'],
        ADMIN_ASSIGNEES: ['assignee_admin', 'admin_dossier']
    },
    STATUS: {
        UNFINISHED_FLOW: "status-nog-niet-afgerond",
        FLOW_COMPLETED: "status-vragenflow-afgerond",
        CLIENT_REQUESTED_HELP: "status-wacht-op-reactie",
        CLIENT_LIVES_IN_LEIDEN_AREA: "status-lives-in-leiden-area",
        PROCESSED_BY_ADMIN: "status-wachten-op-hulpverzoek",
        MORE_INFO_REQUESTED: "status-more-info-requested",
        ASSIGNED_BY_ASSIGNEE_ADMIN: "status-dossier-goedgekeurd",
        TRANSFERRED_INTERNALLY: "status-rhv-assignee-internal-transfer",
        TRANSFERRED_EXTERNALLY: "status-rhv-assignee-external-transfer",
        TRANSFERRED_EXTERNALLY_COMPLETE: "status-rhv-assignee-external-transfer-complete",
        TRANSFERRED_EXTERNALLY_PROPOSED: "status-rhv-assignee-external-transfer-proposed",
        TRANSFERRED_UNREGISTERED: "status-rhv-assignee-external-unregistered",
        REJECTED_BY_ASSIGNEE_ADMIN: "status-rejected-by-assignee-admin",
        REJECTED_BY_ASSIGNEE_WORKER: "status-rejected-by-assignee-worker",
        STOPPED_BY_ASSIGNEE_WORKER: "status-stopped-by-assignee-worker",
        STOPPED_BY_CLIENT: "status-stopped-by-client",
        DOSSIER_HELP_COMPLETED: "status-dossier-afgerond",
        STATUS_CLIENT_DELETED_DOSSIER: 'status-client-deleted-dossier',
        ELAPSED_NOT_ACCEPTED_DOSSIER: "status-elapsed-but-not-accepted-by-assignee-admin",
        ELAPSED_AFTER_ACCEPTED_DOSSIER: "status-elapsed-after-accepted-by-assignee-admin",
        DEFAULT: 'Onbekend',
        CLIENT_DEFAULT: 'Onbekend',
        PAIRS: {// key is the class and value is text
            "status-nog-niet-afgerond": "Vragenflow niet afgerond", // client hasn't completed flow
            "status-vragenflow-afgerond": "Vragenflow afgerond", // flow completed but client hasn't done anything
            "status-lives-in-leiden-area": "Vragenflow afgerond",
            "status-wacht-op-reactie": "Hulpverzoek ingediend", // client has requested help
            "status-more-info-requested": "Meer info nodig", // rhv requested more info before accepting case
            "status-wachten-op-hulpverzoek": "Wachten op hulpverzoek", // admin has assigned case to assignee_admin
            "status-wachten-op-helpdesk": "Wachten op helpdesk", // assignee_admin has assigned case to assignee_worker
            "status-dossier-goedgekeurd": "Dossier actief", //
            "status-rhv-assignee-internal-transfer": "Dossier overgedragen (interne)",
            "status-rhv-assignee-external-transfer": "Dossier overgedragen (externe)",
            "status-rhv-assignee-external-unregistered": "Dossier overgedragen (unregistered)",
            "status-rejected-by-assignee-admin": "Dossier afgekeurd",
            "status-rejected-by-assignee-worker": "Dossier afgekeurd", // case is rejected after it was assigned to worker
            "status-stopped-by-assignee-worker": "Dossier afgekeurd", // c
            "status-client-stopped-dossier": "Cliënt gestopt",
            "status-dossier-afgerond": "Dossier afgerond",
            "status-dossier-in-behandeling": "Dossier in behandeling",
            "status-elapsed-but-not-accepted-by-assignee-admin": "Verlopen",
            "status-elapsed-after-accepted-by-assignee-admin": "Verlopen",
            "status-rhv-assignee-external-transfer-proposed": "Overdrag voorgesteld aan cliënt",
            "status-client-deleted-dossier": "Verwijderd"
        },
        CLIENT_PAIRS: {
            "status-nog-niet-afgerond": "Vragenflow niet afgerond", // client hasn't completed flow
            "status-vragenflow-afgerond": "Vragenflow afgerond", // flow completed but client hasn't done anything
            "status-lives-in-leiden-area": "Vragenflow afgerond",
            "status-wacht-op-reactie": "Hulpverzoek ingediend", // client has requested help
            "status-more-info-requested": "Meer info nodig", // rhv requested more info before accepting case
            "status-wachten-op-hulpverzoek": "Wachten op hulpverzoek", // admin has assigned case to assignee_admin
            "status-wachten-op-helpdesk": "Wachten op helpdesk", // assignee_admin has assigned case to assignee_worker
            "status-dossier-goedgekeurd": "Dossier actief", //
            "status-rhv-assignee-internal-transfer": "Dossier actief",
            "status-rhv-assignee-external-transfer": "Dossier actief",
            "status-rhv-assignee-external-unregistered": "Dossier overgedragen (unregistered)",
            "status-rejected-by-assignee-admin": "Dossier afgekeurd",
            "status-rejected-by-assignee-worker": "Dossier afgekeurd", // case is rejected after it was assigned to worker
            "status-dossier-afgerond": "Dossier afgerond",
            "status-dossier-in-behandeling": "Dossier in behandeling",
            "status-elapsed-but-not-accepted-by-assignee-admin": "Verlopen",
            "status-elapsed-after-accepted-by-assignee-admin": "Verlopen",
            "status-rhv-assignee-external-transfer-proposed": "Dossier actief"
        },
        ASSIGNED_PAIRS: {
            "status-nog-niet-afgerond": "Vragenflow niet afgerond", // client hasn't completed flow
            "status-vragenflow-afgerond": "Vragenflow afgerond", // flow completed but client hasn't done anything
            "status-lives-in-leiden-area": "Vragenflow afgerond",
            "status-wacht-op-reactie": "Hulpverzoek ingediend", // client has requested help
            "status-more-info-requested": "Meer info nodig", // rhv requested more info before accepting case
            "status-wachten-op-hulpverzoek": "Wachten op hulpverzoek", // admin has assigned case to assignee_admin
            "status-wachten-op-helpdesk": "Wachten op helpdesk", // assignee_admin has assigned case to assignee_worker
            "status-dossier-goedgekeurd": "Dossier actief", //
            "status-rhv-assignee-internal-transfer": "Dossier aan u overgedragen (interne)",
            "status-rhv-assignee-external-transfer": "Dossier aan u overgedragen (externe)",
            "status-rhv-assignee-external-unregistered": "Dossier aan u overgedragen (unregistered)",
            "status-rejected-by-assignee-admin": "Dossier afgekeurd",
            "status-rejected-by-assignee-worker": "Dossier afgekeurd", // case is rejected after it was assigned to worker
            "status-dossier-afgerond": "Dossier afgerond",
            "status-dossier-in-behandeling": "Dossier in behandeling",
            "status-elapsed-but-not-accepted-by-assignee-admin": "Verlopen",
            "status-elapsed-after-accepted-by-assignee-admin": "Verlopen",
            "status-rhv-assignee-external-transfer-proposed": "Overdrag voorgesteld aan cliënt",
            "status-client-deleted-dossier": "Verwijderd"

        },
        PRE_REQUEST_STATES: ['status-nog-niet-afgerond', 'status-vragenflow-afgerond'],
        CAN_CHOOSE_ASSIGNEE: [
            'status-lives-in-leiden-area',
            'status-rejected-by-assignee-admin',
            'status-rejected-by-assignee-worker',
            'status-elapsed-but-not-accepted-by-assignee-admin',
            'status-client-stopped-dossier'
        ],
        PICK_RHV: [
            'status-vragenflow-afgerond',
            'status-lives-in-leiden-area',
            'status-rejected-by-assignee-admin',
            'status-rejected-by-assignee-worker',
            'status-elapsed-but-not-accepted-by-assignee-admin',
            'status-stopped-by-assignee-worker',
            'status-client-stopped-dossier'
        ],
        LISTED: [
            'status-more-info-requested',
            'status-wacht-op-reactie',
            'status-wachten-op-hulpverzoek',
            'status-vragenflow-afgerond',
            'status-dossier-goedgekeurd',
            'status-rhv-assignee-internal-transfer',
            'status-rhv-assignee-external-transfer',
            'status-rhv-assignee-external-unregistered',
            'status-dossier-afgerond',
            'status-rhv-assignee-external-transfer-proposed',
            'status-client-deleted-dossier'
        ],
        DASHBOARD: [
            'status-more-info-requested',
            'status-wacht-op-reactie',
        ],
        HIDE_FOOTER: [
            'status-dossier-goedgekeurd',
            'status-dossier-afgerond',
            'status-rhv-assignee-internal-transfer',
            'status-rhv-assignee-external-transfer',
            'status-rhv-assignee-external-unregistered',
            'status-rhv-assignee-external-transfer-proposed',
            'status-client-deleted-dossier'
        ],
        HIDE_FOOTER_FOR_ADMIN: [
            'status-dossier-goedgekeurd',
            'status-dossier-afgerond',
            'status-wacht-op-reactie',
            'status-wachten-op-hulpverzoek',
            'status-rhv-assignee-internal-transfer',
            'status-rhv-assignee-external-transfer',
            'status-rhv-assignee-external-unregistered',
            'status-rhv-assignee-external-transfer-proposed',
            'status-client-deleted-dossier'
        ],
        ADMIN_FOOTER: [
            'status-elapsed-but-not-accepted-by-assignee-admin',
            'status-elapsed-after-accepted-by-assignee-admin',
            'status-stopped-by-assignee-worker',
            'status-rejected-by-assignee-admin',
        ],
        CAN_BE_REASSIGNED_STATES: [
            'status-stopped-by-assignee-worker',
            'status-client-stopped-dossier',
            'status-elapsed-but-not-accepted-by-assignee-admin',
            'status-elapsed-after-accepted-by-assignee-admin',
            'status-rejected-by-assignee-admin',
            'status-rejected-by-assignee-worker'
        ],
        CANNOT_BE_REASSIGNED_STATES: [
            'status-rhv-assignee-external-transfer-proposed'
        ],
        IS_BEING_HELPED: [
            'status-dossier-goedgekeurd',
            'status-rhv-assignee-internal-transfer',
            'status-rhv-assignee-external-transfer',
        ],
    },
    CATEGORY: {
        ALL: 'Alle dossiers',
        EMPLOYMENT: 'Arbeid',
        PURCHASE: 'Consumentenkoop',
        LIVING: 'Wonen',
        FAMILY: 'Persoon/familie',
        GOVERNMENT: 'Overheid',
        BENEFITS: 'Uitkering',
        ALLOWANCE: 'Toeslag',
        PRIVACY: 'AVG',
        POLICE: 'Politie',
        INJURY: 'Letselschade',
        FINE: 'Boete',
        OTHER: 'Anders'

    },
    REVERSE_CATEGORY: {
        'Alle dossiers': 'ALL',
        'Arbeid': 'EMPLOYMENT',
        'Consumentenkoop': 'PURCHASE',
        'Wonen': 'LIVING',
        'Persoon/familie': 'FAMILY',
        'Overheid': 'GOVERNMENT',
        'Uitkering': 'BENEFITS',
        'Toeslag': 'ALLOWANCE',
        'AVG': 'PRIVACY',
        'Politie': 'POLICE',
        'Letselschade': 'INJURY',
        'Boete': 'FINE',
        'Anders': 'OTHER'
    },
    SIMPLE_CATEGORIES: [
        '',
        'Arbeid',
        'Consumentenkoop',
        'Wonen',
        'Persoon/familie',
        'Overheid',
        'Uitkering',
        'Toeslag',
        'AVG',
        'Politie',
        'Letselschade',
        'Boete',
        'Anders',
    ],
    ADMIN_REASSIGN_MESSAGE: {
        "status-elapsed-but-not-accepted-by-assignee-admin": "Deze dossier is verstreken voordat RHV accepteerde. U kunt de dossier aan een andere rechthulverlener toewijzen door op ",
        "status-elapsed-after-accepted-by-assignee-admin": "Deze dossier is verstreken nadat RHV accepteerde. U kunt de dossier aan een andere rechthulverlener toewijzen door op ",
        "status-stopped-by-assignee-worker": "De rechtshulpverlener heeft deze dossier afgewezen. U kunt de dossier aan een andere rechthulverlener toewijzen door op "
    }
});

export const PAGINATION = Object.freeze({
    TWENTY: 20,
    DEFAULT: 15,
    ADMIN_DOSSIER_LIST: 7,
    RHV_COMPANY: 5,
    DOSSIER_QUESTION_COUNT: 15,
});

export const MESSAGE = Object.freeze({
    TYPE: {
        MORE_INFO: 'client_case_more_info_required',
        ASSIGNEE_NEW_THREAD: 'assignee_new_thread',
        ASSIGNEE_REPLY: 'assignee_reply',
        CLIENT_NEW_THREAD: 'client_new_thread',
        CLIENT_REPLY: 'client_reply',
    },
    ERROR: {
        expired_client: "Deze link is niet meer geldig. Vraag een nieuwe link aan.",
        expired_rhv: "Deze link is niet meer geldig. Inloggen met e-mail en wachtwoord.",
    },
    NON_DIALOG_MESSAGES: [
        "new-rhv-registered",
        "rhv-from-csv-registered",
        "csv-rhv-opted-out"
    ]
});

export const ROLES = Object.freeze({
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_USER',
    ASSIGNEE_ADMIN: 'ROLE_ASSIGNEE_ADMIN',
    ASSIGNEE_WORKER: 'ROLE_ASSIGNEE_WORKER',
    DEFAULT: 'ROLE_VIRTUAL_USER'
});

export const MAGIC_LINK = Object.freeze({
    TYPE: {
        ANONYMOUS_USER_REGISTRATION: 'after_anonymous_user_registers_with_real_email',
        EXISTING_USER_CHANGES_EMAIL: 'after_existing_user_changes_email_address',
        CONFIRM_REQUEST_SHARING_DOSSIER: 'client_confirm_request_sharing_dossier',
        UNLOCK_DOSSIER: 'unlock_dossier',
        LOCK_DOSSIER: 'lock_dossier',
        UPDATE_CLIENT_CASE_LAST_VIEWS: 'update_client_last_viewed_at',
    }
});

export const ASSIGNMENT = Object.freeze({
    // !*!*!*! outcome must be less that 40 chars!
    OUTCOME: {
        REJECTED: 'assignee_rejected_case',
        STOPPED: 'assignee_stopped_case',
        STOPPED_BY_CLIENT: 'stopped_by_client',
        SIGNED_OFF_BY_ADMIN: 'admin_signed_case_off_to_assignee_admin', // 39
        ELAPSED_NOT_ACCEPTED: 'assignee_not_accepted_elapsed',
        TRANSFERRED_INTERNALLY: 'rhv_assignee_internal_transfer',
        TRANSFERRED_EXTERNALLY: 'rhv_assignee_external_transfer',
        TRANSFERRED_EXTERNALLY_COMPLETE: 'rhv_assignee_external_transfer_complete',
        TRANSFERRED_EXTERNALLY_AGREED: 'rhv_assignee_external_transfer_agreed',
        TRANSFERRED_EXTERNALLY_REJECTED: 'rhv_assignee_external_transfer_rejected',
        TRANSFERRED_UNREGISTERED: 'rhv_assignee_external_unregistered',
    },
    REJECTION: {
        REASONS: [
            'Momenteel geen tijd',
            'Ander specialisme',
            'Spraken van belangenverstrenging',
            'Andere partij is meer geschikt',
            'Anders',
        ]
    },
    TYPE: {
        ASSIGNED_BY_CLIENT: 'assignment_by_client', // can only be seen by admin
        ASSIGNED_BY_ADMIN: 'assignment_by_admin',  // case is assigned to legal office - only manager of office can see case
        ASSIGNED_BY_ASSIGNEE_ADMIN: 'assignment_by_assignee_admin', // case is assigned within legal office to case worker
        ASSIGNED_BY_ADMIN_TRANSFER: 'assignment_by_admin_transfer',
        ASSIGNED_BY_ASSIGNEE_ADMIN_TRANSFER: 'assignment_by_assignee_admin_transfer',
        ASSIGNED_BY_ASSIGNEE_WORKER_TRANSFER: 'assignment_by_assignee_worker_transfer',
        ASSIGNED_TRANSFERRED_INTERNALLY: 'assignment_transferred_internally',
        ASSIGNED_TRANSFERRED_EXTERNALLY: 'assignment_transferred_externally',
        ASSIGNED_TRANSFERRED_EXTERNALLY_COMPLETE: 'assignment_transferred_externally_complete',
        ASSIGNED_TRANSFERRED_UNREGISTERED: 'assignment_transferred_unregistered',
        ASSIGNED_BY_CLIENT_FOR_ASSIGNEE: 'assignment_by_client_for_assignee',
    },
    LOGBOOK: {
        'assignment_by_client': {text: 'Dossier geopend', overviewText: 'Dossier geopend'},//'ASSIGNED_BY_CLIENT',
        'assignment_by_admin': {text: 'Dossier geopend', overviewText: 'Dossier geopend'},// 'ASSIGNED_BY_ADMIN',
        'assignment_by_assignee_admin': {
            text: 'Dossier toegewezen',
            overviewText: 'Dossier toegewezen'
        }, //ASSIGNED_BY_ASSIGNEE_ADMIN',
        'assignment_by_admin_transfer': {
            text: 'ASSIGNED_BY_ADMIN_TRANSFER',
            overviewText: 'ASSIGNED_BY_ADMIN_TRANSFER'
        }, //ASSIGNED_BY_ADMIN_TRANSFER',
        'assignment_by_assignee_admin_transfer': {
            text: 'ASSIGNED_BY_ASSIGNEE_ADMIN_TRANSFER',
            overviewText: 'ASSIGNED_BY_ASSIGNEE_ADMIN_TRANSFER'
        }, //ASSIGNED_BY_ASSIGNEE_ADMIN_TRANSFER',
        'assignment_by_assignee_worker_transfer': {
            text: 'ASSIGNED_BY_ASSIGNEE_WORKER_TRANSFER',
            overviewText: 'ASSIGNED_BY_ASSIGNEE_WORKER_TRANSFER'
        }, //ASSIGNED_BY_ASSIGNEE_WORKER_TRANSFER',
        'assignment_transferred_internally': {
            text: 'Dossier intern doorverwezen',
            overviewText: 'Dossier intern overgedragen aan hultpverlener: '
        }, //ASSIGNED_TRANSFERRED_INTERNALLY',
        'assignment_transferred_externally': {
            text: 'ASSIGNED_TRANSFERRED_EXTERNALLY',
            overviewText: 'ASSIGNED_TRANSFERRED_EXTERNALLY'
        }, //ASSIGNED_TRANSFERRED_EXTERNALLY',
        'assignment_transferred_externally_complete': {
            text: 'ASSIGNED_TRANSFERRED_EXTERNALLY_COMPLETE',
            overviewText: 'ASSIGNED_TRANSFERRED_EXTERNALLY_COMPLETE'
        }, //ASSIGNED_TRANSFERRED_EXTERNALLY_COMPLETE',
        'assignment_transferred_unregistered': {
            text: 'ASSIGNED_TRANSFERRED_UNREGISTERED',
            overviewText: 'ASSIGNED_TRANSFERRED_UNREGISTERED'
        }, //ASSIGNED_TRANSFERRED_UNREGISTERED'
        'assignment_by_client_for_assignee': {
            text: 'Dossier geopend en toegewezen',
            overviewText: 'Dossier geopend en toegewezen'
        },//ASSIGNED_BY_CLIENT_FOR_ASSIGNEE
    }

});

export const FILE = Object.freeze({
    TYPES: [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'text/csv',
        'application/csv',
        'text/html',
        'application/rtf',
        'text/rtf',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    ],
    IMAGES: [
        'image/jpeg',
        'image/png',
        'image/gif',
    ],
    ERROR: {
        "too-many-files": {
            multiple: false,
            title: 'Te veel bestanden',
            header: '',
            content: ['Too many files upload just one']
        },
        "file-too-large": {
            multiple: false,
            title: 'Bestand te groot    ',
            header: '',
            content: ['Too many files upload just one']
        },
        "default": {
            title: 'Onbekende fout',
            header: '',
            content: ['Een onbekende fout is opgetreden'],
            multiple: false
        }
    },
    ERROR_CODE: {
        "file-invalid-type": "Ongeldig bestand type",
        "file-too-large": "Bestand te groot",
        "file-too-small": "Bestand te klein",
        "too-many-files": "Te veel bestanden",
    }
});

export const SCREEN_SHARE = Object.freeze({
    STATUS: {
        REQUESTED_BY_CLIENT: 'status_requested_by_client',
        STARTED_BY_CLIENT: 'status_started_by_client',
        CLOSED_BY_CLIENT: 'status_closed_by_client',
        ADMIN_STOPPED_SHARING: 'status_admin_stopped_sharing',
        ADMIN_APPROVED_SHARING: 'status_admin_approved_sharing',
        ADMIN_STARTED_SHARING: 'status_admin_started_sharing',
        ADMIN_CANCELLED_SHARE_DIALOG: 'status_admin_cancelled_share_dialog',
        CONNECTED_STATES: ['status_admin_approved_sharing', 'status_started_by_client', 'status_admin_started_sharing'],
        ADMIN_CAN_START_STATES: ['status_admin_stopped_sharing', 'status_started_by_client'],
        ADMIN_DONT_ACCEPT_STATES: ['status_admin_stopped_sharing']
    }
});

export const CLIENT = Object.freeze({
    STATUS: {
        NEW: 'status_new_client',
        REGISTERED: 'status_registered_client',
        PRE_REGISTERED: 'status_pre_registered_client',
        UNREGISTERED: 'status_unregistered_client' // finished flow as unregistered user
    }
});

export const OFFICE = Object.freeze({
    TYPE: {
        NONE: 'Kies hulpverlening',
        LAWYER: 'Advocaat',
        MEDIATORS: 'Mediator',
        LAWYERS: 'Rechtswinkel',
        TRADE_UNION: 'Vakbond',
        LEGAL_COSTS_INSURER: 'Rechtsbijstandverzekeraar',
        SOCIAL_ADVISER: 'Sociaal raadslid',
        LEGAL_COUNTER: 'Juridisch loket',
        SUPERVISOR: 'Toezichthouder',
        SOCIAL_WORKER: 'Sociale hulpverlener', // not visible to client
    },
    QUERY_TYPE: {
        "advocaat": 'Advocaat',
        "mediator": 'Mediator',
        "rechtswinkel": 'Rechtswinkel',
        "vakbond": 'Vakbond',
        "rechtsbijstandverzekeraar": 'Rechtsbijstandverzekeraar',
        "sociaal-raadslid": 'Sociaal raadslid',
        "juridisch-loket": 'Juridisch loket',
        "sociale-hulpverlener": 'Sociale hulpverlener',
        'toezichthouder': 'Toezichthouder',
    },
    INITIAL_MAX_COUNT: {
        'Advocaat': 2,
        'Mediator': 2,
        'Rechtswinkel': 2,
        'Vakbond': 2,
        'Rechtsbijstandverzekeraar': 2,
        'Sociaal raadslid': 2,
        'Juridisch loket': 2,
        'Sociale hulpverlener': 2,
        'Toezichthouder': 2,
    },
    VISIBLE_TO_CLIENT_TYPES: {
        'Rechtswinkel': 'Rechtswinkel',
        'Advocaat': 'Advocaat',
        'Mediator': 'Mediator',
        'Vakbond': 'Vakbond',
        'Verzekeraar': 'Rechtsbijstandverzekeraar',
        'Sociaal raadslid': 'Sociaal raadslid',
        'Juridisch loket': 'Juridisch loket',
        'Toezichthouder': 'Toezichthouder',
        'Reset filters': 'reset_all_filters'
    },
    PLURAL: {
        "Rechtswinkel": "Rechtswinkels",
        "Advocaat": "Advocaten",
        "Mediator": "Mediatoren",
        "Vakbond": "Vakbonden",
        "Rechtsbijstandverzekeraar": "Rechtsbijstandverzekeraars",
        "Sociaal raadslid": "Sociaal raadsleden",
        'Juridisch loket': 'Juridisch lokets',
        "Toezichthouder": "Toezichthouders",
        "Sociale hulpverlener": "Sociale hulpverleners"
    },
    ACCEPTABLE_VALUES: [
        'Rechtswinkel',
        'Advocaat',
        'Mediator',
        'Vakbond',
        'Rechtsbijstandverzekeraar',
        'Sociaal raadslid',
        'Sociale hulpverlener',
        'Juridisch loket',
        'Toezichthouder',
    ],
    REQUIRES_SUBSIDY: ['Advocaat', 'Mediator'],
    SIZE: {
        NULL: "Kies organisatie grootte (aantal medewerkers)",
        SMALL: "Klein 0 - 5",
        MEDIUM: "Middel Klein 5 - 25",
        LARGE: "Groot > 25"
    },
    SUBSIDY: {
        "ja": "Ja",
        "nee": "Nee",
        "nvt": "n.v.t"
    },
    SUBSIDY_TOOLTIP: "Sommige advocaten of mediators kunnen een geldbedrag van de overheid krijgen waarmee een deel van de kosten voor de rechtshulp betaald wordt.",
    PAID_FOR: {
        "gratis": "Gratis",
        "betaald": "Betaald"
    },
    HOURLY_RATE: {
        "-100": "Minder dan €100",
        "100-149": "€100 - €149",
        "150-199": "€150 - €199",
        "200-249": "€200 - €249",
        "250+": "Meer dan €250",
        "fixed_price": "Vaste prijs"
    },
    FREE_INTRODUCTION: {
        "ja": "Ja",
        "nee": "Nee",
    },
    MINIMUM_CLAIM: {
        "250+": "Meer dan €250",
        "500+": "Meer dan €500",
        "nvt": "n.v.t"
    },
    MINIMUM_CLAIM_TOOLTIP: "Dit is de waarde die aan het juridisch probleem gegeven wordt.",
    WAITING_TIME: {
        "-1": "Minder dan 1 maand",
        "1-3": "1 tot 3 maanden",
        "3+": "Meer dan 3 maanden",
        "nvt": "n.v.t"
    },
    ACCEPTS_LAWSUITS: {
        "ja": "Ja",
        "nee": "Nee",
    },
    TERMS_OF_SERVICE_PARAMS: ["possible_subsidy", "free_or_paid", "hourly_rate", "free_introduction", "minimum_claim", "waiting_time", "accepts_lawsuits", "languages"],
    ACCEPTABLE_SIZES: ['small', 'medium', 'large'],
    ACCEPTABLE_SIZE_VALUES: ["Klein 0 - 5", "Middel Klein 5 - 25", "Groot > 25"],
    DEFAULT_HOURS: {
        zondag: {
            isOpen: false,
            hours: []
        },
        maandag: {
            isOpen: true,
            hours: [
                {
                    opening: '09:00',
                    closing: '17:00'
                },
            ]
        },
        dinsdag: {
            isOpen: true,
            hours: [
                {
                    opening: '09:00',
                    closing: '17:00'
                }
            ]
        },
        woensdag: {
            isOpen: true,
            hours: [
                {
                    opening: '09:00',
                    closing: '17:00'
                },
            ]
        },
        donderdag: {
            isOpen: true,
            hours: [
                {
                    opening: '09:00',
                    closing: '17:00'
                },
            ]
        },
        vrijdag: {
            isOpen: true,
            hours: [
                {
                    opening: '09:00',
                    closing: '17:00'
                },
            ]
        },
        zaterdag: {
            isOpen: false,
            hours: []
        },
    },
    DISTANCES: {
        "10": '10km',
        "25": '25km',
        "50": '50km',
        "100": '100km',
        "0": 'Alle afstanden'
    }
});

export const RHV_REGISTRATION = Object.freeze({
    STATUS: {
        RHV_ADMIN_NOT_STARTED: 'status_rhv_admin_not_started',
        RHV_ADMIN_STARTED: 'status_rhv_admin_started',
        RHV_ADMIN_STARTED_FROM_CSV: 'status_rhv_admin_started_from_csv', // company was imported from csv
        RHV_CSV_ADMIN_OPTED_OUT: 'status_rhv_csv_admin_opted_out', // company will be deleted // show RhvRegistrationAfterOptingOut dialog
        RHV_ADMIN_FIRST_STEP_COMPLETED: 'status_rhv_admin_first_step_completed', // show RhvRegistrationFirstStepCompleted dialog
        RHV_ADMIN_MAIL_REGISTER_LINK: 'status_rhv_admin_mail_register_link',
        RHV_ADMIN_MAIL_REGISTER_LINK_EMAIL_ONLY: 'status_rhv_admin_mail_register_link_email_only',// make password dialog open - dialogStatus = password
        RHV_ADMIN_MAIL_FORGOTTEN_PASSWORD_STARTED: 'status_rhv_admin_mail_forgotten_password_started',  // email sent dialog showing - dialogStatus = registered
        RHV_ADMIN_MAIL_FORGOTTEN_PASSWORD_LINK: 'status_rhv_admin_mail_forgotten_password_link', // forgotten password email send - dialogStatus = login
        RHV_ADMIN_PASSWORD_SET: 'status_rhv_admin_password_set',  // password has been updated - login dialog open - dialogStatus = login
        RHV_ADMIN_LOGGED_IN: 'status_rhv_admin_logged_in', // rhv admin logged in - terms & conditions dialog open - dialogStatus = terms
        RHV_ADMIN_FORGOTTEN_PASSWORD: 'status_rhv_admin_forgotten_password', // forgotten password dialog open - dialogStatus = forgotten
        RHV_ADMIN_TERMS_ACCEPTED: 'status_rhv_admin_terms_accepted', // terms and conditions accepted - dialogStatus = welcomedialogStatus = contact
        RHV_ADMIN_FINAL_REGISTRATION_STARTED: 'status_rhv_admin_final_registration_started', // terms and conditions accepted - dialogStatus = welcome
        RHV_ADMIN_CONTACT_INFO_COMPLETE: 'status_rhv_admin_contact_info_complete', // contact info completed - move to home screen - no dialogStatus
        RHV_ADMIN_OFFICE_LAYOUT_COMPLETE: 'status_rhv_admin_office_layout_complete', // office layout info completed -
        RHV_ADMIN_OFFICE_PROFILE_COMPLETE: 'status_rhv_admin_office_profile_complete', // office profile info completed -
        RHV_ADMIN_OFFICE_TERMS_COMPLETE: 'status_rhv_admin_office_terms_complete', // office terms info completed -
        RHV_ADMIN_WELCOME_COMPLETE: 'status_rhv_admin_welcome_complete', // moved on after welcome message - rhv contact info dialog open -
        /* STATUS FOR REGISTRATION OF ASSIGNEE WORKER */
        RHV_WORKER_REGISTER_MAIL_REQUESTED: 'status_rhv_worker_register_mail_requested',
        RHV_WORKER_REGISTER_LINK_MAILED: 'status_rhv_worker_register_link_mailed',
        RHV_WORKER_REGISTER_LINK_RESPONDED: 'status_rhv_worker_register_link_responded',
        RHV_WORKER_PASSWORD_SET: 'status_rhv_worker_password_set',
        RHV_WORKER_TERMS_ACCEPTED: 'status_rhv_worker_terms_accepted',
        RHV_WORKER_WELCOME_COMPLETE: 'status_rhv_worker_welcome_complete',
    },
    TYPE: {
        RHV_COMPANY_CREATE: 'rhv_company_create',
        RHV_COMPANY_ADD_USER: 'rhv_company_add_user',
        RHV_COMPANY_CREATE_FROM_CSV: 'rhv_company_create_from_csv'
    },
    HIDE_DETAILS: [
        'status_rhv_admin_not_started',
        'status_rhv_admin_started',
        'status_rhv_admin_started_from_csv',
        'status_rhv_admin_mail_register_link',
        'status_rhv_admin_mail_register_link_email_only',
        'status_rhv_admin_mail_forgotten_password_started',
        'status_rhv_admin_mail_forgotten_password_link',
        'status_rhv_admin_password_set',
        'status_rhv_admin_forgotten_password',
        'status_rhv_worker_register_mail_requested',
        'status_rhv_worker_register_link_mailed',
        'status_rhv_worker_register_link_responded',
        'status_rhv_worker_password_set',
        'status_rhv_worker_terms_accepted',
        'status_rhv_admin_logged_in'
    ],
    FORWARD_TO_RHV_REGISTRATION: [
        'status_rhv_worker_register_link_mailed',
    ],
    HIDE_RHV_MENU: [
        'status_rhv_admin_started_from_csv'
    ],
    TODO_STATUS: {
        GENERAL_INFO_COMPLETE: 1,
        SPECIALISMS_COMPLETE: 2,
        ORGANISATION_COMPLETE: 4,
        TERMS_COMPLETE: 8,
        TODO_LIST_COMPLETE: 16,
        RHV_REGISTRATION_INCOMPLETE: 23
    },
    TODO_TABS: {
        1: 'ContactCard',
        2: 'LayoutCard',
        4: 'ProfileCard',
        8: 'TermsCard',
        16: 'ForgottenPassword',
    },
    INITIAL_STATE: {
        start: true,
        registered: false,
        password: false,
        login: false,
        forgotten: false,
        terms: false,
        welcome: false,
        contact: false
    },
    INITIAL_SNACKBAR_STATE: {
        start: false,
        registered: false,
        password: false,
        login: false,
        forgotten: false,
        terms: false,
        welcome: false,
        contact: false
    }
});

export const RHV_COMPANY = Object.freeze({
    DEPARTMENT: {
        NULL: 'Voeg afdeling of specialisme toe',
        EMPLOYMENT: 'Werk',
        LIVING: 'Wonen',
        PURCHASE: 'Consumenten koop',
        FAMILY: 'Persoon en familie',
        GOVERNMENT: 'Overheid',
        BENEFITS: 'Uitkeringen',
        FINE: 'Schulden',
        PRIVACY: 'AVG',
        ALLOWANCE: 'Toeslag',
        POLICE: 'Politie',
        INJURY: 'Letselschade',
        ANDERS: 'Anders'

    }
});
/**
 * Keys "development", "acceptance", "production" refer to environment var CURRENT_ENV transferred
 * via docker-compose in gi-law (main)
 */
export const FLOWS = Object.freeze({

    LIST: {
        NOTHING: {
            "development": [],
            "acceptance": [],
            "production": []
        },
        BASIC: { /* label BASIS_VRAGENLIJST in tree-maker */
            "development": [
                {
                    name: 'Hoofdboom',
                    url: '/start-flow-route?q_a0b29438=question&entryId=db160d7e-9013-4f9d-8362-033ee534ea77',
                }, {
                    name: 'Familie & relaties',
                    url: '/start-flow-route?q_a0b29438=6c848d30&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Werk',
                    url: '/start-flow-route?q_a0b29438=6169d608&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Koop product of dienst',
                    url: '/start-flow-route?q_a0b29438=475ec7d7&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Probleem overheid',
                    url: '/start-flow-route?q_a0b29438=7db6bf8c&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Wonen',
                    url: '/start-flow-route?q_a0b29438=105ae785&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Toeslagen',
                    url: '/start-flow-route?q_a0b29438=2e6d89ac&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Uitkering',
                    url: '/start-flow-route?q_a0b29438=78d937cc&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Boete',
                    url: '/start-flow-route?q_a0b29438=5bcc1338&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'AVG',
                    url: '/start-flow-route?q_a0b29438=3d04a5b0&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }
            ],
            "acceptance": [
                {
                    name: 'Familie & relaties',
                    url: '/start-flow-route?q_a0b29438=6c848d30&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Werk',
                    url: '/start-flow-route?q_a0b29438=6169d608&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Koop product of dienst',
                    url: '/start-flow-route?q_a0b29438=475ec7d7&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Probleem overheid',
                    url: '/start-flow-route?q_a0b29438=7db6bf8c&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Wonen',
                    url: '/start-flow-route?q_a0b29438=105ae785&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Toeslagen',
                    url: '/start-flow-route?q_a0b29438=2e6d89ac&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Uitkering',
                    url: '/start-flow-route?q_a0b29438=78d937cc&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Boete',
                    url: '/start-flow-route?q_a0b29438=5bcc1338&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'AVG/Privacy',
                    url: '/start-flow-route?q_a0b29438=3d04a5b0&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Anders/overig',
                    url: '/start-flow-route?q_a0b29438=7cf2d6f6&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }
            ],
            "production": [
                {
                    name: 'Familie & relaties',
                    url: '/start-flow-route?q_a0b29438=6c848d30&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Werk',
                    url: '/start-flow-route?q_a0b29438=6169d608&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Koop product of dienst',
                    url: '/start-flow-route?q_a0b29438=475ec7d7&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Probleem overheid',
                    url: '/start-flow-route?q_a0b29438=7db6bf8c&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Wonen',
                    url: '/start-flow-route?q_a0b29438=105ae785&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Toeslagen',
                    url: '/start-flow-route?q_a0b29438=2e6d89ac&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Uitkering',
                    url: '/start-flow-route?q_a0b29438=78d937cc&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Boete',
                    url: '/start-flow-route?q_a0b29438=5bcc1338&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'AVG/Privacy',
                    url: '/start-flow-route?q_a0b29438=3d04a5b0&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Online Fraude',
                    url: '/start-flow-route?q_a0b29438=766a5831&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Letselschade',
                    url: '/start-flow-route?q_a0b29438=c8b51b3a&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Anders/overig',
                    url: '/start-flow-route?q_a0b29438=7cf2d6f6&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }
            ]
        },
        EXTENSIVE: { /* label UITGEBREIDE_VRAGENLIJST in tree-maker */
            "development": [
                {
                    name: 'Hoofdboom',
                    url: '/start-flow-route?q_06f026c5=question&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4',
                },
                {
                    name: 'Werk ontslag',
                    url: '/start-flow-route?q_06f026c5=7444ad0d&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Werk overig',
                    url: '/start-flow-route?q_06f026c5=5e5951ee&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Familie en Relaties',
                    url: '/start-flow-route?q_06f026c5=0c1c597d&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Uitkering',
                    url: '/start-flow-route?q_06f026c5=4cebda48&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Koop product of dienst',
                    url: '/start-flow-route?q_06f026c5=272a84a6&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Probleem met de overheid',
                    url: '/start-flow-route?q_06f026c5=4f7c9eae&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Toeslagen',
                    url: '/start-flow-route?q_06f026c5=70c74044&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Wonen',
                    url: '/start-flow-route?q_d2437b12=question&entryId=c7fd2d17-7386-4f07-b989-5097fec91dd5'
                },
                {
                    name: 'Boete',
                    url: '/start-flow-route?q_06f026c5=b3e4a4ca&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'AVG',
                    url: '/start-flow-route?q_06f026c5=f3a39cf6&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Aangifte',
                    url: '/start-flow-route?q_06f026c5=a717da7e&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                }
            ],
            "acceptance": [
                {
                    name: 'Familie & relaties',
                    url: '/start-flow-route?q_a0b29438=6c848d30&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Werk',
                    url: '/start-flow-route?q_a0b29438=6169d608&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Koop product of dienst',
                    url: '/start-flow-route?q_a0b29438=475ec7d7&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Probleem overheid',
                    url: '/start-flow-route?q_a0b29438=7db6bf8c&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Wonen',
                    url: '/start-flow-route?q_a0b29438=105ae785&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Toeslagen',
                    url: '/start-flow-route?q_a0b29438=2e6d89ac&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'Uitkering',
                    url: '/start-flow-route?q_a0b29438=78d937cc&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Boete',
                    url: '/start-flow-route?q_a0b29438=5bcc1338&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                },
                {
                    name: 'AVG/Privacy',
                    url: '/start-flow-route?q_a0b29438=3d04a5b0&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }, {
                    name: 'Anders/overig',
                    url: '/start-flow-route?q_a0b29438=7cf2d6f6&entryId=db160d7e-9013-4f9d-8362-033ee534ea77'
                }
            ],
            "production": [
                {
                    name: 'Werk, (dreigend) ontslag of einde contract',
                    url: '/start-flow-route?q_06f026c5=7444ad0d&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Werk, overig',
                    url: '/start-flow-route?q_06f026c5=5e5951ee&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Koop product of dienst',
                    url: '/start-flow-route?q_06f026c5=272a84a6&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Familie en Relatie',
                    url: '/start-flow-route?q_06f026c5=0c1c597d&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Probleem Overheid',
                    url: '/start-flow-route?q_06f026c5=4f7c9eae&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Uitkering',
                    url: '/start-flow-route?q_06f026c5=4cebda48&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Toeslag',
                    url: '/start-flow-route?q_06f026c5=70c74044&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'AVG/Privacy',
                    url: '/start-flow-route?q_06f026c5=f3a39cf6&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Aangifte (politie)',
                    url: '/start-flow-route?q_06f026c5=a717da7e&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Boete',
                    url: '/start-flow-route?q_06f026c5=b3e4a4ca&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Letselschade',
                    url: '/start-flow-route?q_06f026c5=2c18d258&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Strafrecht',
                    url: '/start-flow-route?q_06f026c5=7921f7d9&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Online fraude',
                    url: '/start-flow-route?q_06f026c5=579822a7&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                },
                {
                    name: 'Anders',
                    url: '/start-flow-route?q_06f026c5=4cdafb0c&entryId=af1d49bc-3121-4f76-af6f-c228ae6ec8e4'
                }
            ]
        },
        OTHER: {/* label OVERIGE_VRAGENLIJST in tree-maker */
            "development": [
                {
                    name: 'Toevoegingscheck',
                    url: '/start-flow-route?q_f1d69878=fe86c850&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Hulp van Juridisch Loket',
                    url: '/start-flow-route?q_f1d69878=4710f80c&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Mediator of Advocaat',
                    url: '/start-flow-route?q_f1d69878=17e1f1e8&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Welke rechtshulp heeft u nodig?',
                    url: '/start-flow-route?q_f1d69878=125dcd00&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Meer-zorgen-check',
                    url: '/start-flow-route?q_f1d69878=1c6d3321&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                }

            ],
            "acceptance": [
                {
                    name: 'Toevoegingscheck',
                    url: '/start-flow-route?q_f1d69878=fe86c850&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Hulp van Juridisch Loket',
                    url: '/start-flow-route?q_f1d69878=4710f80c&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Mediator of Advocaat',
                    url: '/start-flow-route?q_f1d69878=17e1f1e8&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Welke rechtshulp heeft u nodig?',
                    url: '/start-flow-route?q_f1d69878=125dcd00&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Meer-zorgen-check',
                    url: '/start-flow-route?q_f1d69878=1c6d3321&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                }
            ],
            "production": [
                {
                    name: 'Toevoegingscheck',
                    url: '/start-flow-route?q_f1d69878=fe86c850&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Hulp van Juridisch Loket',
                    url: '/start-flow-route?q_f1d69878=4710f80c&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Mediator of Advocaat',
                    url: '/start-flow-route?q_f1d69878=17e1f1e8&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Welke rechtshulp heeft u nodig?',
                    url: '/start-flow-route?q_f1d69878=125dcd00&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                },
                {
                    name: 'Meer-zorgen-check',
                    url: '/start-flow-route?q_f1d69878=1c6d3321&entryId=011593e1-da36-4faf-b83a-5fa6472f77c2'
                }
            ]
        },
    },
    CATEGORIES: [
        {
            label: 'Basis vragenlijst',
            category: 'BASIC'
        },
        {
            label: 'Uitgebreide vragenlijst',
            category: 'EXTENSIVE'
        },
        {
            label: 'Overige vragenlijst',
            category: 'OTHER'
        }
    ]
});

export const URLS = Object.freeze({
    HOMEPAGE: 'https://www.rechtsbijstandportaal.nl/',
    HOMEPAGE_LOGIN: 'https://www.rechtsbijstandportaal.nl/inloggen-rechtsbijstandportaal/',
    REGISTER_RHV: 'https://rechtsbijstandportaal.nl/contactformulier-registratie-hulpverleners/',
    RHV_CHOICE_PAGE: 'https://www.rechtsbijstandportaal.nl/@todo',
    VIDEOS: {
        LAWYER: 'https://gi-law-public.s3.eu-central-1.amazonaws.com/videos/infofilmpje_advocatuur.mp4',
        MEDIATORS: 'https://gi-law-public.s3.eu-central-1.amazonaws.com/videos/infofilmpje_mediator.mp4',
        LAWYERS: 'https://gi-law-public.s3.eu-central-1.amazonaws.com/videos/infofilmpje_rechtswinkel.mp4'
    }
});

export const GOTO_PAGES = Object.freeze({
    RESCIND_PROFILE_DELETE: 'rescind_profile_delete',
    RESCIND_DOSSIER_DELETE: 'rescind_dossier_delete',
    DOWNLOAD_CLIENT_DOSSIER: 'download_client_dossier',
    RHV_COMPANY_SETTINGS: 'rhv_company_settings',
    RHV_FROM_CSV_OPTS_OUT: 'rhv_from_csv_opts_out',
    RHV_FROM_CSV_ACTIVATE_ACCOUNT: 'rhv_from_csv_activate_account',
    DOSSIER_LIST: 'dossier_list',
});

