import React, {useEffect, useState} from "react";
import {sendForgottenPasswordLink} from "../../features/users/userSlice";
import {Form, Formik} from 'formik';
import {useDispatch} from "react-redux";
import {doLogin} from "../../features/security/securitySlice";
import GiLawErrorTextWrapper from "../forms/GiLawErrorTextWrapper";
import {getLoginSchema, translateBackendError} from "../../validationSchemas";
import GiLawPasswordFieldWrapper from "../forms/GiLawPasswordFieldWrapper";
import GiLawTextFieldWrapper from "../forms/GiLawTextFieldWrapper";
import GiLawForgottenPasswordForm from "../forms/GiLawForgottenPasswordForm";
import {MESSAGE} from "../../app/constants";
import {Button} from "@mui/material";
import ViewDebugger from "../debug/ViewDebugger";

function Login() {
    const query = new URLSearchParams(window.location.search);
    const email = query.get('email') ?? '';
    const messageKey = query.get('message_key') ?? '';
    const queryParamsMessage = MESSAGE.ERROR[messageKey] ?? null;

    const dispatch = useDispatch();

    const VIEWS = Object.freeze({
        LOGIN: 'login',
        FORGOTTEN: 'forgotten',
        FORGOTTEN_SENT: 'forgotten_sent'
    });

    const [loginFailed, setLoginFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Login mislukt!');
    const [forgottenPwEmail, setForgottenPwEmail] = useState('');
    const [view, setView] = useState(VIEWS.LOGIN);
    let storageLoggedOutMessage = localStorage.getItem('loggedOutMessage');
    const [loggedOutMessage, setLoggedOutMessage] = useState(storageLoggedOutMessage);

    const loginSchema = getLoginSchema([]);

    useEffect(() => {
        setLoggedOutMessage(localStorage.getItem('loggedOutMessage'));
    }, [storageLoggedOutMessage, setLoggedOutMessage]);

    const submitLoginRequest = async (values, {setSubmitting}) => {
        setSubmitting(true);
        setLoginFailed(false);
        let formData = {...values};
        formData.email =  (values.email ?? '').toLowerCase();
        const response = await dispatch(doLogin({formData}));
        if ('security/doLogin/rejected' === response.type) {
            const errorMessage = response?.payload?.message ?? response?.error?.message ?? 'Login mislukt!';
            setErrorMessage(translateBackendError(errorMessage));
            setLoginFailed(true);
        }
        setSubmitting(false);
    };

    const returnToLoginPage = () => {
        setView(VIEWS.LOGIN);
    };

    const forgottenPassword = (evt) => {
        evt.preventDefault();
        setView(VIEWS.FORGOTTEN)
    };

    const handleForgottenPasswordSubmit = async (values, {setSubmitting, setFieldTouched, setErrors}) => {
        setSubmitting(true);
        const email =  (values.email ?? '').toLowerCase();
        const response = await dispatch(sendForgottenPasswordLink({email}));
        if ('users/sendForgottenPasswordLink/rejected' === response.type) {
            const errorMessage = response?.payload?.message ?? response?.error?.message ?? 'Opvraag mislukt!';
            setErrorMessage(translateBackendError(errorMessage));
            setLoginFailed(true);
        } else {
            setForgottenPwEmail(email);
            setView(VIEWS.FORGOTTEN_SENT);
        }
        setSubmitting(false);
    };

    return (
        <div className="basic-background-gradient" data-component="Login">

            <ViewDebugger />
            <div className="basic-background-gradient">
                <div className="uk-text-right logo-login-card">
                    <img width="" height="" alt="" data-uk-img=""
                         src="/images/RBPlogoWit.png"/>
                </div>
                {VIEWS.LOGIN === view && <Formik
                    enableReinitialize
                    initialValues={{
                        email,
                        password: '',
                    }}
                    validationSchema={loginSchema}
                    onSubmit={submitLoginRequest}
                >
                    {({errors, touched, isSubmitting, isValid, values}) => (
                        <Form autoComplete="off">
                            <div className="uk-position-center">
                                <div className="width-login-card">
                                    <div className="uk-card uk-card-default card-radius">
                                        <div className="uk-card-header">
                                            <h3 className="uk-modal-title primary">Inloggen</h3>
                                        </div>
                                        <div className="uk-card-body">
                                            <div>
                                                <div className="uk-margin" data-uk-grid="">
                                                    <div
                                                        className="uk-width-1-3 uk-flex uk-flex-middle">
                                                        <label
                                                            className="uk-form-label">E-mailadres</label>
                                                    </div>
                                                    <GiLawTextFieldWrapper
                                                        placeholder={`Typ hier uw e-mailadres in`}
                                                        fieldName={'email'}
                                                        type={'email'}
                                                        touched={touched}
                                                        errors={errors}
                                                        divClass={`uk-form-controls uk-width-2-3`}
                                                        fieldClass={`uk-input form-with-only-border-bottom`}
                                                        values={values}/>
                                                </div>
                                                <GiLawErrorTextWrapper
                                                    fieldName={'email'}
                                                    touched={touched}
                                                    errors={errors}
                                                    divClass={`uk-width-2-3 uk-inline`}
                                                    bufferClass={`uk-width-1-3 uk-flex uk-flex-middle`}
                                                />
                                                <div className="uk-margin" data-uk-grid="">
                                                    <div
                                                        className="uk-width-1-3 uk-flex uk-flex-middle">
                                                        <label
                                                            className="uk-form-label">Wachtwoord</label>
                                                    </div>
                                                    <GiLawPasswordFieldWrapper
                                                        fieldClass={`uk-input form-with-only-border-bottom`}
                                                        divClass={`uk-width-2-3 uk-inline`}
                                                        errors={errors}
                                                        touched={touched}
                                                        fieldName={`password`}
                                                        placeholder={`Typ hier uw wachtwoord in`}
                                                    />
                                                </div>

                                                <GiLawErrorTextWrapper
                                                    fieldName={'password'}
                                                    touched={touched}
                                                    errors={errors}
                                                    divClass={`uk-width-2-3 uk-inline`}
                                                    bufferClass={`uk-width-1-3 uk-flex uk-flex-middle`}
                                                />
                                            </div>
                                            <div className="uk-flex uk-flex-right@m">
                                                <div className="uk-text-right forgot-password">
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#"
                                                       onClick={async (evt) => forgottenPassword(evt)}>
                                                        Wachtwoord vergeten?
                                                    </a>
                                                </div>
                                            </div>
                                            <>
                                                <div className={`login-failed-alert ${loginFailed ? 'login-failed' : 'not-login-failed'}`}>
                                                    <span className={`font-size-fixed`} >{errorMessage}</span>

                                                    <Button onClick={() => setLoginFailed(false)}
                                                            className="gi-law-close-alert-button" type="button"><img
                                                        src="/icons/close-icon-red.svg"
                                                        alt="close-icon"/></Button>
                                                </div>
                                            </>
                                            {loggedOutMessage && <>
                                                <div className="uk-alert-primary"
                                                     data-uk-alert="">{loggedOutMessage}</div>
                                            </>}
                                            {queryParamsMessage && '' !== queryParamsMessage && <>
                                                <div className="uk-alert-danger"
                                                     data-uk-alert="">{queryParamsMessage}</div>
                                            </>}
                                            <div className="button-login-card login-form">
                                                <p className="uk-text-right">
                                                    <button
                                                        disabled={isSubmitting || !isValid}
                                                        className={`${isValid ? '' : 'invalid-submit-button'} uk-button uk-button-secondary hover-dark-purple `}
                                                        id="login-button"
                                                        type="submit">
                                                        Inloggen
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>}
                {VIEWS.FORGOTTEN === view && <>

                    <GiLawForgottenPasswordForm
                        open={true}
                        returnToLoginPage={returnToLoginPage}
                        initialValues={{
                            email: ''
                        }}
                        errorMessage={errorMessage}
                        loginFailed={loginFailed}
                        handleSubmit={handleForgottenPasswordSubmit}
                        outerClass={`modal-with-less-padding-bottom`}/>
                </>}
                {VIEWS.FORGOTTEN_SENT === view &&
                <>
                    <div
                        className={`uk-modal-dialog-large-wrapper forgotten-password-email-sent-wrapper`}>
                        <div id=""
                             className=" forgotten-password-email-sent min-width-popup-dialog gi-law-all-padding-modal  uk-modal-dialog-large modal-with-less-padding-bottom">
                            <h3 className="uk-modal-title primary">E-mail is verzonden</h3>
                            <hr className="modal-first-hr"/>
                            <p>Er is een e-mail gestuurd naar het door u ingevoerde
                                e-mailadres <span
                                    className="text-middle-bold">{forgottenPwEmail ? ` ${forgottenPwEmail}` : ''}</span>.
                                <br/>Ga naar de e-mail om verder te gaan.</p>
                        </div>
                    </div>
                </>}
            </div>

        </div>
    )
}

export default Login
