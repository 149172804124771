import crypto from "crypto";

class AutoLogoutTimer {
    constructor({onTimeout, onExpired, onBrowserClosed}) {
        this.timeout = 30 * 60;
        this.onTimeout = onTimeout;
        this.onBrowserClosed = onBrowserClosed;
        this.storageKey = 'expirationTime';
        this.identifier = crypto.createHash('sha1').update((Date.now() >>> 0).toString(2)).digest('hex');
        localStorage.removeItem('loggedOutMessage');
        localStorage.removeItem('noMessage');
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
        this.updateTabList(true);
        this.addBrowserCloseTabListener();
    }

    addBrowserCloseTabListener() {
        const self = this;
        window.onbeforeunload = (evt) => {
            self.updateTabList(false);
            delete evt.returnValue;
            return;
        };
        window.addEventListener('load', async (evt) => {
            await self.updateTabList(true);
            delete evt.returnValue;
        });

    }

    async updateTabList(add) {
        let identifiers = (localStorage.getItem('identifiers') ?? '').trim().split(',').filter(identifier => identifier);
        if (add && !identifiers.includes(this.identifier)) {
            identifiers.push(this.identifier);
        } else if (identifiers.includes(this.identifier)) {
            identifiers = identifiers.filter(identifier => identifier ? identifier !== this.identifier : false);
            if (0 === identifiers.length && this.onBrowserClosed) {
                this.onBrowserClosed();
            }
        }
        const joined = identifiers.join(',');
        localStorage.setItem('identifiers', joined);
        return 'something'
    }


    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem(this.storageKey), 10);
            if (expiredTime < Date.now()) {
                if (this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, 1000);
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = setTimeout(() => {
            localStorage.setItem(this.storageKey, Date.now() + this.timeout * 1000);
        }, 300);
    }

    tracker() {
        window.addEventListener('mousemove', this.eventHandler);
        window.addEventListener('scroll', this.eventHandler);
        window.addEventListener('keydown', this.eventHandler);
    }

    cleanUp() {
        localStorage.removeItem(this.storageKey);
        localStorage.removeItem('identifiers');
        const noMessage = localStorage.getItem('noMessage');
        if (!noMessage || 'true' !== noMessage) {
            localStorage.setItem('loggedOutMessage', 'U bent uitgelogd omdat u de afgelopen 30 minuten niet actief was.');
        }
        clearInterval(this.interval);
        this.updateTabList(false);
        window.removeEventListener('mousemove', this.eventHandler);
        window.removeEventListener('scroll', this.eventHandler);
        window.removeEventListener('keydown', this.eventHandler);
    }
}

export default AutoLogoutTimer;
