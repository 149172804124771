import React from "react";
import {Form, Formik} from "formik";
import GiLawTextFieldWrapper from "../forms/GiLawTextFieldWrapper";
import GiLawErrorTextWrapper from "../forms/GiLawErrorTextWrapper";
import {RhvForgottenPasswordSchema} from "../../validationSchemas";


const GiLawForgottenPasswordForm = ({handleSubmit, initialValues, returnToLoginPage, loginFailed,errorMessage }) => {

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    email: '',
                }}
                validationSchema={RhvForgottenPasswordSchema}
                onSubmit={handleSubmit}
            >
                {({errors, touched, isSubmitting, isValid, values}) => (
                    <Form autoComplete="off">
                            <div className="uk-position-center">
                                <div className="width-login-card">
                                    <div className="uk-card uk-card-default card-radius">
                                        <div className="uk-card-header">
                                            <h3 className="uk-modal-title primary">Wachtwoord vergeten</h3>
                                        </div>
                                        <div className="uk-card-body">
                                            <div>
                                                <p>Vul hieronder uw mailadres in, wij sturen u een mail waarmee
                                                    u het
                                                    wachtwoord kan opnieuwe instellen.</p>
                                                <div className="uk-margin" data-uk-grid="">
                                                    <div
                                                        className="uk-width-1-3 uk-flex uk-flex-middle">
                                                        <label
                                                            className="uk-form-label">E-mailadres</label>
                                                    </div>
                                                    <GiLawTextFieldWrapper
                                                        placeholder={`Typ hier uw e-mailadres in`}
                                                        fieldName={'email'}
                                                        type={'email'}
                                                        touched={touched}
                                                        errors={errors}
                                                        divClass={`uk-form-controls uk-width-2-3`}
                                                        fieldClass={`uk-input form-with-only-border-bottom`}
                                                        values={values}
                                                    />
                                                </div>
                                                <GiLawErrorTextWrapper
                                                    fieldName={'email'}
                                                    touched={touched}
                                                    errors={errors}
                                                    divClass={`uk-width-2-3 uk-inline`}
                                                    bufferClass={`uk-width-1-3 uk-flex uk-flex-middle`}
                                                />
                                            </div>
                                            {loginFailed && <>
                                                <div className="uk-alert-danger"
                                                     data-uk-alert="">{errorMessage}</div>
                                            </>}
                                            <div className="button-login-card">
                                                <p className="uk-text-right">
                                                    <button
                                                        onClick={async () => returnToLoginPage()}
                                                        className="uk-button button-default-purple-border hover-transparent close-modal-button"
                                                        type="button" >Terug
                                                    </button>
                                                    <button
                                                        disabled={isSubmitting || !isValid}
                                                        className={`${isValid ? '' : 'invalid-submit-button'} uk-button uk-button-secondary hover-dark-purple`}
                                                        type="submit"
                                                    >Bevestigen
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>

                    </Form>
                )}
            </Formik>
        </>
    );
};
export default GiLawForgottenPasswordForm;
