import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {MediaObjectEntity} from "../../schemas";
import dossierAPI from "../../services/dossierAPI";
import {baseAPI} from "../../services/baseAPI";

const mediaObjectAdapter = createEntityAdapter()

export const uploadAssignmentNoteFile = createAsyncThunk("client/uploadAssignmentNoteFile", async ({formData, dossierId, assignmentId}) => {
    const results = await baseAPI.post('/api/media_objects' , formData);
    const normalized = normalize(results.data, MediaObjectEntity);
    return {entities: normalized.entities, dossierId, assignmentId};
});

export const uploadMessageFile = createAsyncThunk("client/uploadMessageFile", async ({formData}) => {
    const results = await baseAPI.post('/api/media_objects' , formData);
    const normalized = normalize(results.data, MediaObjectEntity);
    return normalized.entities;
});

export const uploadResultTreeFile =  createAsyncThunk("client/uploadResultTreeFile", async ({formData}) => {
    const results = await baseAPI.post('/api/media_objects' , formData);
    const normalized = normalize(results.data, MediaObjectEntity);
    return normalized.entities;
});

export const uploadRhvCompanyProfileMedia =  createAsyncThunk("client/uploadRhvCompanyProfileMedia", async ({formData}) => {
    const results = await baseAPI.post('/api/media_objects' , formData);
    const normalized = normalize(results.data, MediaObjectEntity);
    return normalized.entities;
});

export const fetchMediaObject = createAsyncThunk("mediaObjects/fetchMediaObject", async (uri) => {
    const results = await baseAPI.get(uri);
    const normalized = normalize(results.data, MediaObjectEntity);
    return normalized.entities;
})

export const deleteMediaObject = createAsyncThunk("mediaObjects/deleteMediaObject", async (uri) => {
    await dossierAPI.delete(uri);
    return uri;
});

export const deleteMediaObjectByAssignee =  createAsyncThunk("mediaObjects/deleteMediaObjectByAssignee", async ({id, formData}) => {
    const uri = `/api/media_objects/${id}/rhv_assignee_delete`;
    const result = await dossierAPI.patch(uri, formData);
    return {result, id};
});

const mediaObjectSlice = createSlice({
    name: 'mediaObjects',
    initialState: mediaObjectAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMediaObject.fulfilled, (state, {payload}) => {
            mediaObjectAdapter.upsertMany(state, payload.mediaObjects ?? [])
        })
            .addCase(fetchMediaObject.rejected, (state, {meta}) => {
                const uriParts = meta.arg.split('/')
                const id = uriParts[3]

                mediaObjectAdapter.removeOne(state, id)
            })
            .addCase(uploadResultTreeFile.fulfilled, (state, {payload}) => {
                mediaObjectAdapter.upsertMany(state, payload.mediaObjects ?? [])
            })
            .addCase(uploadRhvCompanyProfileMedia.fulfilled, (state, {payload}) => {
                mediaObjectAdapter.upsertMany(state, payload.mediaObjects ?? [])
            })
            .addCase('dossiers/getDossier/fulfilled', (state, {payload}) => {
                const dossiers = Object.values(payload.dossiers)
                let files = []

                dossiers.forEach(dossier => {
                    const {resultTree} = dossier
                    const questions = resultTree?.questions ?? []
                    questions
                        .filter(_question => _question['@type'] === 'FileUploadQuestion' && _question.hasOwnProperty('mediaObjects'))
                        .forEach(_question => {
                            files = files.concat(_question.mediaObjects)
                        })
                })

                const normalized = normalize(files, [MediaObjectEntity]);
                mediaObjectAdapter.upsertMany(state, normalized.entities.mediaObjects ?? [])
            })
            .addCase(deleteMediaObject.fulfilled, (state, {payload}) => {
                const uriParts = payload.split('/')
                const id = uriParts[3]

                mediaObjectAdapter.removeOne(state, id)
            })
            .addCase(deleteMediaObjectByAssignee.fulfilled, (state, {payload}) => {
                if(payload.result?.data?.success === true && payload.id){
                    mediaObjectAdapter.removeOne(state, payload.id);
                }
            })
    }
})

// export const {setToken, logOut} = dossierSlice.actions

export default mediaObjectSlice.reducer

// Rename the exports for readability in component usage
export const {
    selectById: getMediaObjectById
} = mediaObjectAdapter.getSelectors(state => state.mediaObjects)

export const selectMediaObjectById = id =>
    createSelector(
        [
            state => getMediaObjectById(state, id),
        ],
        (mediaObject) => {
            return mediaObject
        }
    );
