import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {getTreePublication} from "../tree/treeSlice";
import {DateTime, Interval} from 'luxon'
import hash from "hash-sum";
import {
    getAnswerText,
    replacePlaceholders
} from "../../services/questionHelper";
import {removeInvisibleCharacters} from "../../services/stringHelper";

export const SUMMARY_META_KEYS = ['4']
export const RED_FLAG_META_KEYS = ['01']
export const ACHTERKLEP_META_KEYS = ['achterklep']

// Use the uri as ID
const metadataAdapter = createEntityAdapter()

// Metadata slice
const metadataSlice = createSlice({
    name: 'metadata',
    initialState: metadataAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
            builder.addCase(getTreePublication.fulfilled, (state, {payload}) => {
                metadataAdapter.upsertMany(state, payload.metadata ?? [])
            })
    }
});

// export const {} = metadataSlice.actions;

export default metadataSlice.reducer

// Rename the exports for readability in component usage
export const {
    selectById: selectMetadataById,
    selectEntities: selectMetadataEntities,
} = metadataAdapter.getSelectors(state => state.metadata)

export const selectSummaryLinesFromResultTree = (dossier, metaKeys = SUMMARY_META_KEYS) => () => {
    const {resultTree } = dossier;
    if (!resultTree) {
        return [];
    }
    let lines = [];
    const isBespokeDossier = 'bespoke-dossier' === dossier.tree;
    if (resultTree && isBespokeDossier) {
        lines = [resultTree?.summary]
    }else if (resultTree && resultTree?.questions) {
        resultTree.questions.forEach(_question => {
            const chosenAnswers = _question.answers.filter(_answer => _answer.chosen)
            const entityMetadata = findEntityMetadata(_question, chosenAnswers, resultTree)
            let  questionLines = findSummaryMetadata(entityMetadata, metaKeys, _question, chosenAnswers[0], resultTree);
            lines = lines.concat(questionLines)

        });
    }

    return lines
}

export const formatResultTreeAnswers = (questions, resultTree) => () => {
    let answers = [];
    if (questions) {
        questions.forEach(question => {
            const answerText = getAnswerText(question, resultTree, true)
                .replace(/^\s*\\w/, "") // Trim newline from front
                .replace(/\\w\s*$/, "") // Trim newline from back
                .trim();
            let questionText = ['ChooseComplexity','TextNode'].includes(question['@type']) || !question.displayIndex ? question.name : `${question.displayIndex}. ${question.name}`;

            answers.push({
                questionId: question.id,
                question: questionText,
                answer: answerText,
                '@type': question['@type'],
                isError: question.isError
            });
        });
    }
    return answers;
};

export const findMetadataByKeys = (resultTree, question, answer, metaKeys = ACHTERKLEP_META_KEYS) => () => {
    const answerMetadata = findAnswerMetadata([answer], question)
    const questionMetadata = findQuestionMetadata(question)

    return {
        'question': questionMetadata.filter(_meta => _meta?.metaValue && _meta.metaValue.length > 1 && metaKeys.includes(_meta.metaKey)),
        'answer': answerMetadata.filter(_meta => _meta?.metaValue && _meta.metaValue.length > 1 && metaKeys.includes(_meta.metaKey)),
    }
}

const findEntityMetadata = (question, chosenAnswers = [], resultTree) => {
    // Get metadata for each given answer
    const answerMetadata = findAnswerMetadata(chosenAnswers, question)

    // Get meta for question
    const questionMetadata = findQuestionMetadata(question)

    // Get the metadata for all the rules
    const ruleMetadata = question || chosenAnswers.length === 1 ? findValidRules(question, chosenAnswers[0], resultTree) : []

    return [].concat(questionMetadata, answerMetadata, ruleMetadata)
}

const findAnswerMetadata = (chosenAnswers = [], question) => {
    if (!question || question.skipped === true || chosenAnswers.length === 0) {
        return []
    }

    let answerMetadata = []

    chosenAnswers.forEach(_answer => {
        answerMetadata = answerMetadata.concat(_answer?.metadata ?? [])

        // Check for metadata in Choice option
        if (question['@type'] === 'ChoiceQuestion') {
            const choice = question.choices.find(_choice => _choice.id === _answer.id)

            if (choice && choice.hasOwnProperty('metadata') && choice.metadata.length > 0) {
                answerMetadata = answerMetadata.concat(choice.metadata).filter(_meta => {
                    return removeInvisibleCharacters(_meta?.metaValue).length > 0
                })
            }
        }
    })

    return answerMetadata
}

const findQuestionMetadata = (question) => {
    if (!question) {
        return []
    }

    if (question.skipped === true) {
        return question?.skipChoice?.metadata ?? []
    }

    return question?.metadata ?? []
}

const findSummaryMetadata = (metadataResults, metaKeys = [], question, answer, resultTree) => {
    const lines = []

    metadataResults.filter(_meta => _meta !== undefined).forEach(metadata => {
        if (metadata && (metaKeys.length === 0 || metaKeys.includes(metadata.metaKey))) {
            const metaValue = removeInvisibleCharacters(metadata.metaValue)
            if (metaValue.length > 0) {
                lines.push(replacePlaceholders(metadata.metaValue, answer?.name ?? '', question, resultTree))
            }
        }
    })
    return lines
}

const findValidRules = (question, answer, resultTree) => {
    const rules = question?.rules ?? []
    let results = []

    rules
        // .map(_rule => allRules[_rule.id])
        .filter(_rule => ruleIsValid(_rule, question, answer, resultTree))
        .forEach(_rule => {
            results = results.concat(_rule.metadata)
        })

    return results
}

const ruleIsValid = (rule, question, answer, resultTree) => {
    // rule.expressionType: int | date | string
    // rule.expressionValue
    // rule.formula: days_from_now | answer | option | custom
    // rule.operator: eq | lt | lte | gte | gt

    let givenAnswer = answer

    if (!givenAnswer) {
        // Try to find the answer from the question answers
        givenAnswer = question.answers.find(_answer => _answer.chosen === true)

        if (!givenAnswer) {
            return false
        }
    }

    if (rule.formula === 'custom') {
        return validateCustomFormula(rule.expressionValue, question, givenAnswer, resultTree)
    }

    const answerValue = getAnswerValue(question, givenAnswer, rule)
    const expressionValue = getExpressionValue(rule)

    return validateExpression(answerValue, expressionValue, rule.operator)
}

const getAnswerValue = (question, answer, rule) => {
    const {expressionType, formula} = rule
    const {answers} = question
    const {name} = answer

    switch (question['@type']) {
        case 'DateQuestion':
            if (formula === 'days_from_now') {
                return getDaysFromNow(name)
            }

            if (expressionType === 'date') {
                return DateTime.fromFormat(name, 'd-M-yyyy').toSeconds()
            }

            return name
        case 'ChoiceQuestion':
            // Multiple choice, return the option number (a = 0, b = 1 etc)
            return answers.findIndex(_answer => _answer.chosen)
        default:
            if (expressionType === 'string') {
                return name.toLowerCase()
            }

            if (expressionType === 'int' && isNumeric(name)) {
                return parseInt(name)
            }

            return name
    }
}

const getExpressionValue = (rule) => {
    const {expressionValue, expressionType, formula} = rule

    switch (expressionType) {
        case 'int':
            return parseInt(expressionValue)
        case 'date':
            return DateTime.fromFormat(expressionValue, 'dd-M-yyyy').toSeconds()
        case 'string':
        default:
            if (formula === 'option') {
                // Try to convert A, a etc into integer
                if (isNumeric(expressionValue)) {
                    // Already a valid int
                    return parseInt(expressionValue)
                }

                if (expressionValue.length === 1) {
                    return convertCharacterToInt(expressionValue)
                }
            }

            return expressionValue.toLowerCase()
    }
}

function getDaysFromNow(dateAsString, format = 'd-M-yyyy') {
    const start = DateTime.fromFormat(dateAsString, format)
    const end = DateTime.now().startOf('days')

    if (start < end) {
        const interval = Interval.fromDateTimes(start, end)

        return interval.length('days')
    }

    const interval = Interval.fromDateTimes(end, start)

    return interval.length('days')
}

function isNumeric(num) {
    return !isNaN(num)
}

function convertCharacterToInt(text) {
    return text.toLowerCase().charCodeAt(0) - 97
}

function convertIntToCharacter(index) {
    if (index < 0) {
        return index
    }

    return String.fromCodePoint(97 + index).toUpperCase()
}

const validateExpression = (value, compareValue, operator) => {
    switch (operator) {
        case 'eq':
            return value === compareValue
        case 'lt':
            return value < compareValue
        case 'lte':
            return value <= compareValue
        case 'gte':
            return value >= compareValue
        case 'gt':
            return value > compareValue
        default:
            return false;
    }
}

/**
 * Look for string like '18-05-2021' in the expression
 * and replace with date in seconds
 */
const replaceDatesWithSeconds = (expressionValue) => {
    return expressionValue.replace(/'\d{1,2}-\d{1,2}-\d{4}'/g, (match) => {
        const dateAsString = match.slice(1, -1)

        return DateTime.fromFormat(dateAsString, 'd-M-yyyy').toSeconds()
    })
}

export const replaceTagsWithValues = (expressionValue, question, answer, resultTree) => {
    const regex = /\[.+?\]/g;

    return expressionValue.replace(regex, (match) => {
        /**
         * Supported tags:
         *
         * [answer.date]
         * [answer.option]
         * [answer.text]
         * [answer.date.daysFromNow]
         * [question.#514c6821.date]
         * [question.#514c6821.option]
         * [question.#514c6821.text]
         * [question.#514c6821.date.daysFromNow]
         */

        const obj = {
            'date': (value) => {
                return `${DateTime.fromFormat(value, 'd-M-yyyy').toSeconds()}`
            },
            'option': (value, answers) => `'${convertIntToCharacter(answers.findIndex(_answer => _answer.chosen))}'`,
            'text': (value = '', answers, format = 'text') => {
                switch (format) {
                    case 'currency':
                        return parseFloat(value.replaceAll(',', '.'))
                    case 'integer':
                        return parseInt(value)
                    case 'text':
                    default:
                        return `'${value}'`
                }
            },
            'date.daysFromNow': (dateAsString) => getDaysFromNow(dateAsString),
        }

        const tag = match.slice(1, -1)
        const targetValue = getTargetValue(tag, question, answer, resultTree)

        // remove first item and put back together
        const property = getTagPropertyPath(tag)

        if (obj.hasOwnProperty(property)) {
            const targetAnswers = property === 'option' ? getTargetAnswers(tag, question, resultTree) : []

            return obj[property](targetValue, targetAnswers, question?.format)
        }

        return match
    })
}

export const validateCustomFormula = (formula, question, answer, resultTree) => {
    const expressionValue = replaceDatesWithSeconds(formula)
    const parsedFormula = replaceTagsWithValues(expressionValue, question, answer, resultTree)
        .replace(/\sor\s/g, ' || ')
        .replace(/\sand\s/g, ' && ')
        .replace(/\s=\s/g, ' === ')

   // console.log(expressionValue, ' => ', parsedFormula)

    try {
        const f1 = createFunction(parsedFormula)

        return f1()
    } catch (error) {
      //  console.log('Error parsing formula')
     //   console.log('expressionValue', formula)
     //   console.log('parsedFormula', parsedFormula)
        return false
    }
}

const getTagPropertyPath = (tag) => {
    const parts = tag.split('.').slice(1)
    const firstPart = parts[0] ?? null;
    if (firstPart && firstPart.startsWith('#')) {
        parts.shift()
    }

    return parts.join('.')
}

export function createFunction(parsedFormula) {
    /* eslint-disable-next-line */
    return new Function(`return ${parsedFormula};`)
}

const getTargetValue = (tag, question, answer, resultTree) => {
    let targetEntity = answer
    const tagParts = tag.split('.')
    tagParts.shift();
    const firstTagPart = tagParts[0] ?? null;
    if (firstTagPart && firstTagPart.startsWith('#')) {
        // Tag is something like [question.#514c6821.text]
        const targetQuestion = getQuestionFromResultTree(tagParts[0], resultTree)

        if (!targetQuestion || !targetQuestion.hasOwnProperty('answers')) {
            return ''
        }

        targetEntity = targetQuestion.answers.find(_answer => _answer.chosen === true)

        return targetEntity?.name ?? ''
    }

    return typeof targetEntity === 'object' ? targetEntity?.name ?? '' : answer
}

const getTargetAnswers = (tag, question, resultTree) => {
    const tagParts = tag.split('.')
    tagParts.shift()

    if (tagParts[0].startsWith('#')) {
        // Tag is something like [question.#514c6821.text]
        const targetQuestion = getQuestionFromResultTree(tagParts[0], resultTree)

        if (!targetQuestion || !targetQuestion.hasOwnProperty('answers')) {
            return []
        }

        return targetQuestion.answers
    }

    return question.answers
}

const getQuestionFromResultTree = (hashString, resultTree) => {
    const {questions} = resultTree
    const hashToCompare = hashString.slice(1)

    return questions.find(_question => hashToCompare === hash(_question.id))
}
