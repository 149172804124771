import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {getTreePublication} from "../tree/treeSlice";

const ruleAdapter = createEntityAdapter()

const ruleSlice = createSlice({
    name: 'rules',
    initialState: ruleAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTreePublication.fulfilled,(state, {payload}) => {
            ruleAdapter.upsertMany(state, payload.rules ?? [])
        })
    }
});

export default ruleSlice.reducer
