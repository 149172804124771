import React from "react";

const ShowPasswordWidget = ({showing, setShowing}) => {
    return (
        <>
            <span onClick={() => setShowing('password' === showing ? 'text' : 'password')} className="check-password-eye">
                <span className={'password' === showing ? '' : 'uk-hidden'}>
                    <i className="fas fa-eye"/>
                </span>
                <span className={'password' === showing ? 'uk-hidden' : ''}>
                    <i className="fas fa-eye-slash"/>
                </span>
            </span>
        </>
    );
};

export default ShowPasswordWidget;
