import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {treeEntity} from "../../schemas";
import {treeAPI} from "../../services/baseAPI";

const treeAdapter = createEntityAdapter()

export const getTreePublication = createAsyncThunk("tree/getTreePublication", async (uri) => {
    const results = await treeAPI.get(uri);

    const normalized = normalize(JSON.parse(results.data.data), treeEntity);
    return normalized.entities;
})

const treeSlice = createSlice({
    name: 'trees',
    initialState: treeAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTreePublication.fulfilled, (state, {payload}) => {
            treeAdapter.upsertMany(state, payload.trees ?? [])
        })
    }
})

// export const {setToken, logOut} = dossierSlice.actions

export default treeSlice.reducer

// Rename the exports for readability in component usage
export const {
    selectById: selectTreeById,
} = treeAdapter.getSelectors(state => state.trees)
