import React from 'react';
import './scss/fonts.css';
import './gilaw/site.scss';
import './scss/print.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'uikit/dist/js/uikit.min.js';
import '@fortawesome/fontawesome-pro/js/all.js';
import Authentication from "./views/security/Authentication";
import LogoutTimerWrapper from "./views/security/LogoutTimerWrapper";
import ViewDebugger from "./views/debug/ViewDebugger";
function App() {
    return (
        <Authentication>
            <Router>
                <LogoutTimerWrapper />

                <ViewDebugger />
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route path="/" name="Home" render={props => <MainLayout {...props} />} />
                    </Switch>
                </React.Suspense>
            </Router>
        </Authentication>
    )
}

// Containers
const MainLayout = React.lazy(() => import('./containers/Layout'));

// Loading message
const loading = (
    <div className="pt-3 text-center">
        loading...
    </div>
)

export default App;
