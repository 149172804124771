import React from "react";

const GiLawErrorTextWrapper = ({fieldName, errors, touched, bufferClass, divClass, wrapperClass, ignoreTouched = false, forceError =false, forceErrorText = ''}) => {

    if (!(errors[fieldName] && (touched[fieldName] || ignoreTouched)) && !forceError) {
        return (<> </>);
    }

    const errorClass = (errors[fieldName] && (touched[fieldName]|| ignoreTouched)) ||  forceError ? 'gi-law-error-field-wrapper' : '';
    const errorMessage =forceError ? forceErrorText: errors[fieldName];
    return (
        <>
            <div data-component={`GiLawErrorTextWrapper`}
                 className={`${wrapperClass} uk-grid ${errorClass}`}>
                <div className={`${bufferClass} gi-law-error-field-buffer `}>&nbsp;</div>
                <div className={`${divClass} gi-law-error-field-error`}>{errorMessage}</div>
            </div>
        </>
    );
};

export default GiLawErrorTextWrapper;
