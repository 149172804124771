import React, {useState} from "react";
import FontViewer from "./FontViewer";
import {Button} from "@mui/material";
import ClearLocalStorage from "./ClearLocalStorage";
import env from "@beam-australia/react-env";

const ViewDebugger = () => {
    const [isVisible, setVisible] = useState(false);
    const showFontViewer = 'true' === env('SHOW_FONT_DEBUG_VIEWER_ADMIN');
    const showClearLocalStorage = 'true' === env('SHOW_CLEAR_LOCAL_STORAGE_ADMIN');
    if (!showFontViewer && !showClearLocalStorage) {
        return (<></>);
    }
    const toggleVisible = () => {
        setVisible(!isVisible);
    };
    return (
        <>
            <ToggleButton toggleVisible={toggleVisible} isVisible={isVisible}/>

            <div className={`${isVisible ? '' : 'view-debugger-outer-hidden'} view-debugger-outer`}
                 data-component="ViewDebugger">
                <div className="view-debugger-wrapper">
                    {showClearLocalStorage && <ClearLocalStorage/>}
                    {showFontViewer && <FontViewer isVisible={isVisible}/>}
                </div>
            </div>
        </>
    );
};

const ToggleButton = ({toggleVisible, isVisible}) => {
    return (
        <>
            <div
                className={`toggle-button-outer ${isVisible ? 'toggle-button-outer-visible' : 'toggle-button-outer-hidden'}`}
                data-component="ViewDebugger ToggleButton">
                <div className="toggle-button-wrapper">
                    <Button className="" type="button"
                            onClick={() => toggleVisible()}><img
                        src="/icons/close-icon.svg"
                        alt="close-icon"/></Button>
                </div>
            </div>

        </>
    );
};
export default ViewDebugger;
