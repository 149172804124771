import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {UserEntity} from "../../schemas";
import {baseAPI} from "../../services/baseAPI";

const assigneeAdapter = createEntityAdapter();

export const fetchAssignees = createAsyncThunk("users/fetchAssignees", async () => {
    const results = await baseAPI.get(`/api/users/assignees`);
    const normalized = normalize(results.data['hydra:member'], [UserEntity]);
    return normalized.entities;
});

const assigneeSlice = createSlice({
    name: 'assignees',
    initialState: assigneeAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAssignees.fulfilled, (state, {payload}) => {
            assigneeAdapter.upsertMany(state, payload.users ?? [])
        })
    }
});


export default assigneeSlice.reducer

// Rename the exports for readability in component usage
export const {
    selectAll: selectAllAssignees,
} = assigneeAdapter.getSelectors(state => state.assignees);

export const getAssigneeById = (id) =>
    createSelector(
        [
            state => state.assignees?.entities,
        ],
        (entities) => {
            if (!entities) {
                return null;
            }
            return entities[id] ?? null;
        }
    )
