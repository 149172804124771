import React, {useEffect, useState} from "react";
import ComponentIdentifier from "./ComponentIdentifier";
import CssPathIdentifier from "./CssPathIdentifier";


const FontViewer = ({isVisible}) => {
    const [fontSize, setFontSize] = useState('');
    const [fontFamily, setFontFamily] = useState('');
    const [fontWeight, setFontWeight] = useState('');
    const [pseudo, setPseudo] = useState('');
    const [evt, setEvt] = useState(null);
    const [color, setColor] = useState('');
    const [pathSelector, setPathSelector] = useState({
        parts: [],
        show: false,
        len: 0
    });
    let parts = [];
    const hexDigits = "0123456789abcdef".split('');
    const rgb2hex = (rgb) => {
        rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        const rgb1 = rgb ? rgb[1] : null;
        const rgb2 = rgb ? rgb[2] : null;
        const rgb3 = rgb ? rgb[3] : null;
        return "#" + hex(rgb1) + hex(rgb2) + hex(rgb3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleMouseOver = (evt) => {
        if(evt.altKey || evt.shiftKey){
            return;
        }
        setEvt(evt);
        setPseudo('');
        if (['INPUT','TEXTAREA'].includes(evt.target.nodeName)) {
            const placeholderStyle = getComputedStyle(evt.target, ':placeholder');
            setPseudo(`${placeholderStyle.fontSize} ${placeholderStyle.fontFamily} ${placeholderStyle.fontWeight} ${rgb2hex(placeholderStyle.color)}`)
        }
        const style = getComputedStyle(evt.target);
        setFontSize(style.fontSize);
        setFontFamily(style.fontFamily.substr(0, 40));
        setFontWeight(style.fontWeight);
        setColor(rgb2hex(style.color));
        const pathArray = Array.from(evt.composedPath());
        parts = [];
        const ignore = ['#document', 'html', 'body', 'div#root ', 'div#content ',''];
        pathArray.forEach(part => {
            let text = part.nodeName ? part.nodeName.toLowerCase() : '';
            const skip = ignore.includes(text);
            if(part.id && '' !== part.id){
                text += `#${part.id} `;
            }
            const skipTwo = ignore.includes(text);
            if(part.className && '' !== part.className){
                text += `.${part.className}`;
            }
            if (!(skip || skipTwo)) {
                parts.push( text );
            }
        })
        setPathSelector({parts, show: evt.shiftKey, len: pathArray.length});
    };
    const hex = (x) => {
        return isNaN(x) ? "00" : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
    }

    useEffect(() => {
        window.addEventListener("mouseover", handleMouseOver);
        return () => {
            window.removeEventListener("mouseover", handleMouseOver);
        };
    }, [handleMouseOver]);


    return (
        <>
            <div className="font-viewer-outer" data-component="FontViewer">
                {pathSelector.show && <div className="path-viewer" >
                    {pathSelector.parts.map(_part => <p key={_part} className="no-margin">{_part}</p>)}
                </div>}
                <div className="font-viewer-wrapper">
                    <div>{fontSize}</div>
                    <div className="font-family">{color}</div>
                    <div className="font-family">{fontWeight}</div>
                    <div className="font-family">{fontFamily}</div>
                    <div className="font-family font-family-pseudo">{pseudo}</div>
                    <ComponentIdentifier evt={evt} isVisible={isVisible} />
                    <CssPathIdentifier
                        parts={pathSelector.parts}
                        isVisible={isVisible} />
                </div>
            </div>
        </>
    );
};

export default FontViewer;
