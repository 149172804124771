import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {DossierEntity} from "../../schemas";
import dossierAPI from "../../services/dossierAPI";
import fileDownload from 'js-file-download';
import {
    updateAssignment,
    assignCase,
    updateAssignmentNote,
    deleteAssignmentNote,
    addNoteToAssignment,
    deleteAssignmentTask,
    updateAssignmentTask,
    addTaskToAssignment
} from "./assignmentSlice";
import {postMessage} from "./messageSlice";
import {
    deleteMediaObject,
    deleteMediaObjectByAssignee, uploadAssignmentNoteFile,
    uploadResultTreeFile
} from "./mediaObjectSlice";
import {getOriginalFileName} from "../../services/questionHelper";
import {DOSSIER} from "../../app/constants";

const dossierAdapter = createEntityAdapter()

export const fetchDossiers = createAsyncThunk("dossiers/fetchDossiers", async (
    props
) => {
    const results = await dossierAPI.list(props);
    const normalized = normalize(results.data['hydra:member'], [DossierEntity]);
    return {entities: normalized.entities, total: results.data['hydra:totalItems']};
});

export const getRhvSearchAutocompleteList = createAsyncThunk("dossiers/getRhvSearchAutocompleteList", async () => {
    const results = await dossierAPI.fetchRhvSearchAutocompleteList();
    return results.data;
});

export const fetchDossiersWithoutDocumentParams = createAsyncThunk("dossiers/fetchDossiersWithoutDocumentParams", async ({page = null}) => {
    const results = await dossierAPI.listWithoutDocumentParams({page});
    const normalized = normalize(results.data['hydra:member'], [DossierEntity]);
    return {entities: normalized.entities, total: results.data['hydra:totalItems']};
});

export const fetchRzNameForDossiers = createAsyncThunk("dossiers/fetchRzNameForDossiers", async (
    {page = null, category = null, statuses = null}
) => {
    const results = await dossierAPI.list({
        page,
        category,
        statuses,
        rhv: true,
        dashboard: false,
        just_names: true
    });
    return results;
});

export const fetchDossierByEmail = createAsyncThunk("dossiers/fetchDossierByEmail", async (
    {page = 1, email = null}
) => {
    const results = await dossierAPI.listByEmail({page, email});
    const normalized = normalize(results.data['hydra:member'], [DossierEntity]);
    return {entities: normalized.entities, total: results.data['hydra:totalItems']};
});

export const downloadDossier = createAsyncThunk("dossiers/downloadDossier", async ({id, params = {}}) => {
    const response = await dossierAPI.download(`/api/client_cases/${id}/download`, params);
    const filename = response.headers['file-name'] ?? 'unknown-file.zip';
    fileDownload(response.data, filename);

});

export const createBespokeDossier = createAsyncThunk("dossiers/createBespokeDossier", async ({formData = {}}, {rejectWithValue}) => {
    try {
        const results = await dossierAPI.createBespoke(formData);
        const normalized = normalize(results.data, DossierEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const downloadResultTreeMedia = createAsyncThunk("dossiers/downloadResultTreeMedia", async ({id}) => {
    const response = await dossierAPI.download(`/api/client_cases/${id}/zip-media-objects`);
    const filename = response.headers['file-name'] ?? 'unknown-file.zip';
    fileDownload(response.data, filename);
});

export const getDossier = createAsyncThunk("dossiers/getDossier", async (id) => {
    const results = await dossierAPI.get(id);
    const normalized = normalize(results.data, DossierEntity);

    return normalized.entities;
})

export const updateDossier = createAsyncThunk("dossiers/updateDossier", async ({id, formData}) => {
    const results = await dossierAPI.patch(`/api/client_cases/${id}`, formData);
    return results.data;
});

export const deleteDossier = createAsyncThunk("dossiers/deleteDossier", async (id) => {
    await dossierAPI.delete(`/api/client_cases/${id}`);
    return {deletedId: id};
});

export const getMagicLink = createAsyncThunk("dossiers/getMagicLink", async (id) => {
    const results = await dossierAPI.getMagicLink(id);
    return results.data;
});

export const getDossierCategories = createAsyncThunk("dossiers/getDossierCategories", async () => {
    const results = await dossierAPI.listCategories();
    return results.data;
});

export const getDossierStatuses = createAsyncThunk("dossiers/getDossierStatuses", async () => {
    const results = await dossierAPI.listStatuses();
    return results.data;
});

export const getDossierAutocompleteList = createAsyncThunk("dossiers/getDossierAutocompleteList", async () => {
    const results = await dossierAPI.listActionableDossiersAutocomplete();
    return results.data;
});

const dossierInitialState = {
    categories: [],
    statuses: [],
};

const dossierSlice = createSlice({
    name: 'dossiers',
    initialState: dossierAdapter.getInitialState(dossierInitialState),
    reducers: {
        remove: (state, {payload: {deletedId}}) => {
            let {ids, entities} = state;
            if (ids.includes(deletedId)) {
                delete entities[deletedId];
                state.ids = ids.filter((id) => id !== deletedId);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDossiers.fulfilled, (state, {payload}) => {
                    state.ids = [];
                    state.entities = {};
                    dossierAdapter.upsertMany(state, payload?.entities?.dossiers ?? [])
                    state.total = payload?.total;
                })
            .addCase(fetchDossiersWithoutDocumentParams.fulfilled, (state, {payload}) => {
                    state.ids = [];
                    state.entities = {};
                    dossierAdapter.upsertMany(state, payload?.entities?.dossiers ?? [])
                    state.total = payload?.total;
                })
            .addCase(fetchDossierByEmail.fulfilled, (state, {payload}) => {
                state.ids = [];
                state.entities = {};
                dossierAdapter.upsertMany(state, payload?.entities?.dossiers ?? [])
                state.total = payload?.total;
            })
            .addCase(getDossier.fulfilled, (state, {payload}) => {
                dossierAdapter.upsertMany(state, payload.dossiers)
            })
            .addCase(createBespokeDossier.fulfilled, (state, {payload}) => {
                dossierAdapter.upsertMany(state, payload.dossiers)
            })
            .addCase(updateAssignment.fulfilled, (state, {payload}) => {
                let {ids, entities} = state;
                if (payload?.action === 'delete' && payload?.deletedId) {
                    if (ids.includes(payload.deletedId)) {
                        delete entities[payload.deletedId];
                        state.ids = ids.filter((id) => id !== payload.deletedId);
                    }
                }
                if (payload?.action === 'updateDossierAssignment' && payload?.dossierId && payload?.assignmentId && payload.sharedWith) {
                    if (ids.includes(payload.dossierId)) {
                        let dossier = entities[payload.dossierId];
                        dossier.assignees = dossier.assignees.map(assignment => {
                            if (assignment.id === payload.assignmentId) {
                                assignment.sharedWith = payload.sharedWith;
                                return assignment;
                            }
                            return assignment;
                        });
                    }
                }
                if (payload?.action === 'updateClientPhone'
                    && null !== payload?.phonePermission
                    &&  payload?.dossierId
                    && payload?.assignmentId
                    && ids.includes(payload.dossierId)
                ) {
                    let dossier = entities[payload.dossierId];
                    dossier.assignees = dossier.assignees.map(assignment => {
                        if (assignment.id === payload.assignmentId) {
                            assignment.phonePermission = payload.phonePermission;
                            return assignment;
                        }
                        return assignment;
                    });
                }
                if (payload?.action === 'updateDossierStatus' && payload?.dossierId && payload.status) {
                    if (ids.includes(payload.dossierId)) {
                        let dossier = entities[payload.dossierId];
                        dossier.status = payload.status;
                    }
                }
                if (payload.assignment) {
                    const [assignment] = Object.values(payload.assignment);
                    if (ids.includes(assignment?.clientCase?.id)) {
                        let dossier = entities[assignment?.clientCase?.id];
                        dossier.assignees = dossier.assignees.map(assignee => {
                            if (assignee.id === assignment.id) {
                                return assignment;
                            }
                            return assignee;
                        });
                    }
                }

            })
            .addCase(assignCase.fulfilled, (state, {payload}) => {
                const [assignment] = Object.values(payload.assignment);
                if (assignment) {
                    const dossierId = assignment.clientCase?.id;
                    if (state.ids.includes(dossierId)) {
                        let assignees = state.entities[dossierId].assignees ?? [];
                        assignees.push(assignment);
                        state.entities[dossierId].assignees = assignees;
                    }
                }
            })
            .addCase(postMessage.fulfilled, (state, {payload}) => {
                const [message] = Object.values(payload.message);
                if (!message.firstMessage && state.ids.includes(message.clientCase.id)) {
                    const dossiers = Object.values(state.entities);
                    let [dossier] = dossiers.filter((dossier) => dossier.id === message.clientCase.id);
                    if (null === message.questionId) {
                        message.messageOpen = true;
                    }
                    dossier.messages.unshift(message);
                }
            })
            .addCase(updateDossier.fulfilled,(state, {payload}) => {
                if (state.ids.includes(payload.id)) {
                    let entity = state.entities[payload.id];
                    entity.locked = payload.locked ?? entity.locked;
                    entity.magicLinkType = payload.magicLinkType ?? entity.magicLinkType;
                    entity.lastViewedAt = payload.lastViewedAt ?? entity.lastViewedAt;
                    entity.status = payload.status ?? entity.status;
                    entity.deleteAfter = payload.deleteAfter;
                }
            })
            .addCase(deleteMediaObject.fulfilled, (state, {payload: uri}) => {
                Object.values(state.entities).map((dossier) => {
                    return dossier.resultTree.questions.map((question) => {
                        if (question.mediaObjects) {
                            let fileNames = [];
                            let [answer] = question.answers
                            question.mediaObjects = question.mediaObjects.filter((file) => {
                                const notThisFile = file['@id'] !== uri;
                                if (notThisFile) {
                                    fileNames.push(getOriginalFileName(file.contentUrl));
                                }
                                return notThisFile;
                            });
                            if (0 === fileNames.length) {
                                answer.name = 'Geen bestanden aanwezig';
                            } else {
                                answer.name = fileNames.join(', ');
                            }
                            question.answers = [answer];
                        }
                        return question;
                    })
                });
            })
            .addCase(deleteMediaObjectByAssignee.fulfilled, (state, {payload}) => {
                if (payload.result?.data?.success === true && payload.id) {
                    Object.values(state.entities).map((dossier) => {
                        return dossier.resultTree.questions.map((question) => {
                            if (question.mediaObjects) {
                                let fileNames = [];
                                let [answer] = question.answers
                                question.mediaObjects = question.mediaObjects.filter((file) => {
                                    const notThisFile = file.id !== payload.id;
                                    if (notThisFile) {
                                        fileNames.push(getOriginalFileName(file.contentUrl));
                                    }
                                    return notThisFile;
                                });
                                if (0 === fileNames.length) {
                                    answer.name = 'Geen bestanden aanwezig';
                                } else {
                                    answer.name = fileNames.join(', ');
                                }
                                question.answers = [answer];
                            }
                            return question;
                        })
                    });
                }
            })
            .addCase(uploadResultTreeFile.fulfilled, (state, {payload}) => {
                let [mediaObject] = Object.values(payload?.mediaObjects)
                const entity = state.entities[mediaObject?.dossierId];
                if (entity && 'result_tree_upload' === mediaObject.type) {
                    entity.resultTree.questions =  entity.resultTree.questions.map((question) => {
                        if (question.id === mediaObject.questionId) {
                            let [answer] = question.answers;
                            const isFirstDocument = 'Geen bestanden aanwezig' === answer.name;
                            const fileNames = isFirstDocument ? [] : answer.name.split(', ');
                            fileNames.push(mediaObject.fileName);
                            answer.name = fileNames.join(', ');
                            question.answers = [answer];
                            let objects = question.mediaObjects ?? [];
                            objects.push(mediaObject);
                            question.mediaObjects = objects;
                        }
                        return question;
                    })
                }
            })
            .addCase(getMagicLink.fulfilled, (state, {payload}) => {
                if (state.entities[payload.id]) {
                    const entity = state.entities[payload.id];
                    Object.assign(entity, {
                        magicLink: payload.magicLink,
                        magicLinkType: payload.magicLinkType,
                    });
                }
            })
            .addCase(getDossierCategories.fulfilled, (state, {payload}) => {
                state.categories = payload;
            })
            .addCase(getDossierStatuses.fulfilled, (state, {payload}) => {
                state.statuses = payload;
            })
            /* eslint-disable-next-line */
            .addCase('users/patchUser/fulfilled', (state, {payload}) => {
                const [user] = Object.values(payload?.users ?? {});
                if (user?.params?.registerUnregisteredClient && user?.params?.dossierId && user?.params?.isClient) {
                    const dossierId = user.params.dossierId;
                    if (state.ids.includes(dossierId)) {
                        state.entities[dossierId].status = DOSSIER.STATUS.FLOW_COMPLETED;
                    }
                }
            })
            .addCase(updateAssignmentNote.fulfilled, (state, {payload}) => {
                let {ids, entities} = state;
                let [assignmentNote] = Object.values(payload?.assignmentNote);
                if (assignmentNote) {
                    const assignmentId = assignmentNote?.assignment?.id;
                    const dossierId = assignmentNote?.assignment?.clientCase?.id;
                    if (ids.includes(dossierId)) {
                        let assignments = entities[dossierId].assignees;
                        assignments = assignments.map((assignment => {
                            if (assignment.id === assignmentId) {
                                assignment.notes = assignment.notes.map((note) => {
                                    if (assignmentNote.id === note.id) {
                                        return assignmentNote;
                                    }
                                    return note;
                                })
                            }

                            return assignment;
                        }));
                        entities[dossierId].assignees = assignments;
                    }
                }
            })
            .addCase(deleteAssignmentNote.fulfilled, (state, {payload}) => {
                let {ids, entities} = state;
                const {id: assignmentNoteId, dossierId, assignmentId} = payload;
                if (ids.includes(dossierId)) {
                    let assignments = entities[dossierId].assignees;
                    assignments = assignments.map((assignment => {
                        if (assignment.id === assignmentId) {
                            assignment.notes = assignment.notes.filter((note) => {
                                return assignmentNoteId !== note.id;
                            })
                        }

                        return assignment;
                    }));
                    entities[dossierId].assignees = assignments;
                }
            })
            .addCase(addNoteToAssignment.fulfilled, (state, {payload}) => {
                let {ids, entities} = state;
                let [assignmentNote] = Object.values(payload?.assignmentNote);

                if (assignmentNote) {
                    const assignmentId = assignmentNote?.assignment?.id;
                    const dossierId = assignmentNote?.assignment?.clientCase?.id;
                    if (ids.includes(dossierId)) {
                        let assignments = entities[dossierId].assignees;
                        assignments = assignments.map((assignment => {
                            if (assignment.id === assignmentId) {
                                assignment.notes.push(assignmentNote);
                            }
                            return assignment;
                        }));
                        entities[dossierId].assignees = assignments;
                    }
                }
            })
            .addCase(deleteAssignmentTask.fulfilled, (state, {payload}) => {
                let {ids, entities} = state;
                const {id: assignmentTaskId, dossierId, assignmentId} = payload;
                if (ids.includes(dossierId)) {
                    let assignments = entities[dossierId].assignees;
                    assignments = assignments.map((assignment => {
                        if (assignment.id === assignmentId) {
                            assignment.tasks = assignment.tasks.filter((task) => {
                                return assignmentTaskId !== task.id;
                            })
                        }
                        return assignment;
                    }));
                    entities[dossierId].assignees = assignments;
                }
            })
            .addCase(updateAssignmentTask.fulfilled, (state, {payload}) => {
                const {ids, entities: dossiers} = state;
                const {taskId, dossierId, assignmentId, tasks} = payload;
                if (assignmentId && dossierId && ids.includes(dossierId)) {
                    const currentDossier = dossiers[dossierId];
                    let assignments = currentDossier.assignees;
                    currentDossier.assignments = assignments.map((assignment) => {
                        if (assignment.id === assignmentId) {
                            const [updatedTask] = Object.values( tasks.assignmentTask);
                            if(updatedTask){
                                assignment.tasks = assignment.tasks.map((_task) => {
                                    if (_task.id === taskId) {
                                        return updatedTask;
                                    }
                                    return _task;
                                })
                            }
                        }
                        return assignment;
                    });
                }
            })
            .addCase(addTaskToAssignment.fulfilled, (state, {payload}) => {
                const {ids, entities: dossiers} = state;
                const {assignmentId, dossierId, tasks = {}} = payload;
                const [task] = Object.values(tasks?.assignmentTask);
                if (assignmentId && dossierId && ids.includes(dossierId) && task) {
                    const currentDossier = dossiers[dossierId];
                    let assignments = currentDossier.assignees;
                    currentDossier.assignments = assignments.map((assignment) => {
                        if (assignment.id === assignmentId) {
                            assignment.tasks.push(task);
                        }
                        return assignment;
                    });
                }
            })
            .addCase(uploadAssignmentNoteFile.fulfilled, (state, {payload}) => {
                const {ids, entities: dossiers} = state;
                const {assignmentId, dossierId, entities = {}} = payload;
                const [mediaObject] = Object.values(entities?.mediaObjects ?? {});
                if (assignmentId && dossierId && mediaObject && ids.includes(dossierId)) {
                    const currentDossier = dossiers[dossierId];
                    let assignments = currentDossier.assignees;
                    currentDossier.assignments = assignments.map((assignment) => {
                        if (assignment.id === assignmentId) {
                            assignment.notes = assignment.notes.map(
                                (note) => {
                                    if (note['@id'] === mediaObject.assignmentNote) {
                                        note.attachments.push(mediaObject);
                                    }
                                    return note;
                                }
                            );
                        }
                        return assignment;
                    });
                    dossiers[dossierId] = currentDossier;

                }
            })

    },
});

export const {remove: removeDossier} = dossierSlice.actions;

export default dossierSlice.reducer

// Rename the exports for readability in component usage
export const {
    selectById: getDossierById,
    selectAll: selectAllDossiers,
    selectTotal: selectTotalDossiers
} = dossierAdapter.getSelectors(state => state.dossiers)

export const selectDossierById = id =>
    createSelector(
        [
            state => getDossierById(state, id),
            state => state.security.token,
        ],
        (dossier, token) => {
            if (!token) {
                return undefined
            }

            return dossier
        }
    )
