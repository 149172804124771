import {baseAPI} from "./baseAPI";

const dossierAPI = {
  list(props) {
    let queryParams = [];
    const pagination = 'pagination=true';
    const dashboard = props.dashboard ?? false;
    let useRhv = false;
    for (const [key, value] of Object.entries(props)) {
      if (value) {
        if ('page' === key || 'category' === key) {
          queryParams.push(`${key}=${value}`);
          if (!queryParams.includes(pagination)) {
            queryParams.push(pagination);
          }
        }else if ('statuses' === key && Array.isArray(value) && value.length > 0) {
          if (!queryParams.includes(pagination)) {
            queryParams.push(pagination);
          }
          value.forEach(status => {
            queryParams.push(`status[]=${status}`);
          })
        }else if ('rhv' === key && value) {
          useRhv = true;
        }else if(value){
          queryParams.push(`${key}=${value}`);
        }
      }
    }
    queryParams.push(`dashboard=${dashboard}`);
    const query = queryParams.length > 0 ? `?${queryParams.join('&')}` : ``;
    const uri = useRhv ? `/api/client_cases/for_rhv_company${query}` : `/api/client_cases${query}`;
    return baseAPI.get(uri)
  },
  listByEmail({email, page}){
    const uri = `/api/client_cases/get_dossiers_by_client_email?page=${page}&email=${email}`;
    return baseAPI.get(uri)
  },
  listWithoutDocumentParams({page}){
    const uri = `/api/client_cases/dossiers_without_document_params?page=${page}&pagination_items_per_page=20`;
    return baseAPI.get(uri)
  },
  fetchRhvSearchAutocompleteList(){
    const uri = `/api/client_cases/rhv_search_autocomplete_list`;
    return baseAPI.get(uri)
  },
  createBespoke(formData) {
    return baseAPI.post(`/api/client_cases/create_bespoke_dossier`, formData)
  },
  get(id) {
    return baseAPI.get(`/api/client_cases/${id}`);
  },
  getMagicLink(id) {
    return baseAPI.get(`/api/client_cases/${id}/magic_link`);
  },
  delete(uri) {
    return baseAPI.delete(uri);
  },
  patch(uri, formData) {
    return baseAPI.patch(uri, formData);
  },
  download(uri, params = {}) {
    return baseAPI.download(uri, params)
  },
  listCategories() {
    return baseAPI.get(`/api/client_cases/grouped_by_category`);
  },
  listStatuses() {
    return baseAPI.get(`/api/client_cases/get_dossier_statuses`);
  },
  listActionableDossiersAutocomplete() {
    return baseAPI.get(`/api/client_cases/actionable_dossier_autocomplete_list_for_admin`, {pagination:true, page: 1});
  },
};

export default dossierAPI;
