import {schema} from "normalizr";

// definite normalizr entity schemas
export const MediaObjectEntity = new schema.Entity("mediaObjects");
export const UserEntity = new schema.Entity("users");
export const DossierEntity = new schema.Entity("dossiers", {
    createdBy: UserEntity,
});
export const screenSharesEntity = new schema.Entity("screenShares");
export const assignmentsEntity = new schema.Entity("assignment");
export const assignmentNoteEntity = new schema.Entity("assignmentNote");
export const assignmentTaskEntity = new schema.Entity("assignmentTask");
export const messagesEntity = new schema.Entity("message");

// Tree schemas
export const treePublicationEntity = new schema.Entity("treePublications");
const ruleEntity = new schema.Entity("rules");
const choiceEntity = new schema.Entity("choices");
const questionEntity = new schema.Entity("questions", {
    choices: [choiceEntity],
    rules: [ruleEntity],
    skipChoice: choiceEntity,
    mediaObjects: [MediaObjectEntity],
});
const nodeEntity = new schema.Entity("nodes", {
    questions: [questionEntity],
});
const treeNodeEntity = new schema.Entity("treeNodes", {
    expandedNode: nodeEntity,
});
export const treeEntity = new schema.Entity("trees", {
    treeNodes: [treeNodeEntity],
});

export const RhvCompanyEntity = new schema.Entity("rhvCompanies");
export const RhvCompanyUserEntity = new schema.Entity("rhvCompanyUsers");
