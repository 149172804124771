import {ROLES} from "../../app/constants";
import {createAsyncThunk, createEntityAdapter, createSlice, createSelector} from '@reduxjs/toolkit'
import {baseAPI} from "../../services/baseAPI";
import {jws} from "jsrsasign";
import LocalStorageService from "../../services/storage/LocalStorageService";
import env from "@beam-australia/react-env";
import {normalize} from "normalizr";
import {UserEntity} from "../../schemas";
import axios from "axios";

const userAdapter = createEntityAdapter({
    selectId: entity => entity.email
})

export const doLogin = createAsyncThunk("security/doLogin", async ({formData}, {rejectWithValue}) => {
    try {
        const result = await baseAPI.post('/authentication_token', formData)

        // Store accessToken (in localStorage)
        LocalStorageService.setToken(result.data);

        return parseAndValidateAccessToken(result.data.token, false)
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const handleMagicLinkLogin = createAsyncThunk("security/handleMagicLinkLogin", async ({auth, clientCase},{rejectWithValue}) => {
    try {
        const {data} = await axios.get(auth);

        // Store accessToken (in localStorage)
        LocalStorageService.setToken(data)

        return {data, clientCase}
    }catch (error) {
        return rejectWithValue(error.response?.data);
    }

})

export const refreshAccessToken = async () => {
    const result = await baseAPI.post('/api/token/refresh', {
        refresh_token: LocalStorageService.getRefreshToken()
    })

    // Store accessToken (in localStorage)
    LocalStorageService.setToken(result.data)

    return result.data
}

export const handleTokenRefresh = createAsyncThunk("security/handleTokenRefresh", () => {
    return new Promise((resolve, reject) => {
        if (!LocalStorageService.getRefreshToken()) {
            reject()
        }

        refreshAccessToken().then(data => {
            resolve(parseAndValidateAccessToken(data.token, false))
        }).catch(() => {
            reject()
        })
    })
})

export const fetchUsers = createAsyncThunk("security/fetchUsers", async () => {
    const results = await baseAPI.get('/api/users')
    const normalized = normalize(results.data['hydra:member'], [UserEntity]);

    return normalized.entities;
})

export const getToken = () => {
    const accessToken = LocalStorageService.getAccessToken()

    if (accessToken) {
        return parseAndValidateAccessToken(accessToken)
    }

    return false
}

const parseAndValidateAccessToken = (accessToken, verify = true) => {
    if (verify === false || jws.JWS.verifyJWT(accessToken, env('JWT_PUBLIC_KEY'), {alg: ["RS256"]})) {
        const token = jws.JWS.parse(accessToken)

        return token.payloadObj
    }

    return false
}

const securitySlice = createSlice({
    name: 'security',
    initialState: {
        isLoggedIn: false,
        redirectToClientCase: undefined,
        redirectToUser: undefined,
        token: {},
        totalCases: 0,
        users: userAdapter.getInitialState(),
    },
    reducers: {
        setToken: {
            reducer(state, action) {
                state.isLoggedIn = true
                state.token = action.payload
            },
        },
        logOut: {
            reducer(state, action) {
                LocalStorageService.clearToken();
                state.isLoggedIn = false;
                state.token = {}
            },
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(doLogin.fulfilled, (state, action) => {
                state.isLoggedIn = true
                state.token = action.payload
            })
            .addCase(handleTokenRefresh.fulfilled, (state, action) => {
                state.isLoggedIn = true
                state.token = action.payload
                state.totalCases = action.payload?.totalCases ?? 0;
            })
            .addCase(handleTokenRefresh.rejected, (state, action) => {
                LocalStorageService.clearToken()

                state.isLoggedIn = false
                state.token = {}

            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                userAdapter.upsertMany(state.users, action.payload.users)
            })
            .addCase(handleMagicLinkLogin.fulfilled, (state, {payload}) => {
                const {data, clientCase} = payload
                if (data?.failed) {
                    return;
                }
                state.isLoggedIn = true
                state.token = parseAndValidateAccessToken(data.token, false);
                state.redirectToUser = state.token?.id;
                state.redirectToClientCase = clientCase
                state.totalCases = state.token?.totalCases ?? 0;
            })
            .addCase('users/patchUser/fulfilled', (state, {payload}) => {
                const [user] = Object.values(payload.users);
                state.token.isAvailable = user.isAvailable;
                if (user?.token && user.params.isClient) {
                    state.token = parseAndValidateAccessToken(user.token, false);
                    LocalStorageService.setToken(user);
                }
            })
    }
});

export const {setToken, logOut: doLogout} = securitySlice.actions

export default securitySlice.reducer

// Rename the exports for readability in component usage
export const {
    selectById: selectUserById,
    selectIds: selectUserIds,
    selectEntities: selectUserEntities,
    selectAll: selectAllUsers,
    selectTotal: selectTotalUsers
} = userAdapter.getSelectors(state => state.security.users)

export const userHasRole = (role) =>
    createSelector([
            state => state.security.token
        ],
        (token) => {
            if (token && token.roles && token.roles.includes(role)) {
                return true;
            }
            return false;
        }
    );
/** NOTE: employee type is ADMIN, RZ or IF RHV then the type = (email-only, email-only-zip, email-only-link, regular) */
export const getUserEmployerType = (currentUser) =>
    createSelector([
            state => state.security.token
        ],
        (token) => {
        const isClient = token && Array.isArray(token?.roles) && !token.roles.includes(ROLES.ADMIN) && !token.roles.includes(ROLES.ASSIGNEE_ADMIN) &&
            !token.roles.includes(ROLES.ASSIGNEE_WORKER)
            let employerType;
           switch (true) {
               case typeof  token && Array.isArray(token?.roles) && (token.roles).includes(ROLES.ADMIN):
                   employerType = ['ADMIN'];
                   break;
               case isClient:
                   employerType =  ['RZ'];
                   break;
               case currentUser && Array.isArray(currentUser.employers) && currentUser.employers.length > 0:
                   employerType = currentUser && Array.isArray(currentUser.employers) && currentUser.employers.length > 0 ? currentUser.employers.map(employer => {
                           return employer.employedBy.accountType
                       }) : ['loading'];
                   break;
               default:
                   employerType = ['loading']
           }
            return employerType[0]
        }
    );
export const userIsRhv = () =>
    createSelector([
            state => state.security.token
        ],
        (token) => {
            return !!(token &&
                token.roles && (
                    token.roles.includes(ROLES.ADMIN) ||
                    token.roles.includes(ROLES.ASSIGNEE_ADMIN) ||
                    token.roles.includes(ROLES.ASSIGNEE_WORKER))
            );
        }
    );

export const userIsAnonymous = () =>
    createSelector([
            state => state.security.token
        ],
        (token) => {
            if (!token || !token.roles) {
                return true;
            }
            if (token.roles.includes(ROLES.DEFAULT) && 1 === token.roles.length) {
                return true;
            }
            return false;
        }
    );
