import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {RHV_REGISTRATION} from "../../app/constants";
import {getUser} from "../users/userSlice";
import jsonPath from "jsonpath";

const rhvFlowAdapter = createEntityAdapter();

const flowInitialState = {
    registrationStatus: RHV_REGISTRATION.STATUS.RHV_ADMIN_NOT_STARTED,
    registrationStart: {
        officeName: '',
        adminName: '',
        adminEmail: '',
        address: '',
        zipCode: '',
        city: '',
        phone: '',
        website:'',
        officeType: '',
        subscriptionPrice: '',
        acceptedSubscriptionTerms: false,
        areas: {
            bestuursrecht: false,
            huurrecht: false,
            sociaalzekerheidsrecht: false,
            goederenrecht: false,
            arbeidsrecht: false,
            verbintenissenrecht: false,
            "privaatrecht-algemeen": false,
            erfrecht: false,
            "persoon-en-familierecht-voorwaardelijk": false,
            slachtofferzaken: false,
            jeugdstrafrecht: false,
            letselschade: false,
            strafrecht: false,
            strafpiket: false
        },
        initialised: false
    },
    clientPostcode: null,
    clientDistance: null,
    rhvCompanyId: null,
    rhvUserId: null,
    companyContactInfo: {
        name: null,
        address: null,
        zipCode: null,
        city: null,
        phone: null,
        website: null,
        officeSize: null,
        type: null,
        subsidy: null,
    }

};

const rhvRegistrationFlowSlice = createSlice({
        name: 'rhvRegistrationFlow',
        initialState: rhvFlowAdapter.getInitialState(flowInitialState),
        reducers: {
            setStartParameters: (state, {payload}) => {
                Object.assign(state.registrationStart, payload);
            },
            setAreasParameters: (state, {payload}) => {
               Object.assign(state.registrationStart.areas, payload ?? {});
            },
            setCompanyContactInfo: (state, {payload}) => {
                if (typeof payload.type === 'undefined' || null === payload.type) {
                    delete payload.type;
                }
                Object.assign(state.companyContactInfo, payload);
            },
            setStatus: (state, {payload}) => {
                state.registrationStatus = payload.registrationStatus;
            },
            setRhvCompanyId: (state, {payload}) => {
                state.rhvCompanyId = payload.rhvCompanyId;
            },
            setRhvUserId: (state, {payload}) => {
                state.rhvUserId = payload.rhvUserId;
            },
            setClientPostcode:(state, {payload}) => {
                state.clientPostcode = payload.clientPostcode;
                state.clientDistance = payload.clientDistance;
            },
        },
        extraReducers: (builder) => {
            builder.addCase(getUser.fulfilled, (state, {payload}) => {
                const [rhvCompanyId] = jsonPath.query(payload?.users ?? {}, '$..employers[0].employedBy.id');
                if (rhvCompanyId) {
                    state.rhvCompanyId = rhvCompanyId;
                }
            })
        }
    }
);

export const {
    setStartParameters: updateStartParameters,
    setAreasParameters: updateAreasParameters,
    setCompanyContactInfo: updateCompanyContactInfo,
    setStatus: setRegistrationStatus,
    setRhvCompanyId: setRegistrationRhvCompanyId,
    setRhvUserId: setRegistrationRhvUserId,
    setClientPostcode: setClientPostcodeValue,
} = rhvRegistrationFlowSlice.actions;

export default rhvRegistrationFlowSlice.reducer;
export const getCompanyContactInfoParams = (rhvCompany) =>
    createSelector(
        [
            state => state.rhvRegistrationFlow,
        ],
        (rhvRegistrationFlow) => {
            let result = {};
            rhvCompany = rhvCompany ?? {}
            for (const [key,] of Object.entries(rhvRegistrationFlow.companyContactInfo)) {
                if ('subsidy' === key) {
                    result.subsidy = rhvCompany.subsidy ? 'true' : 'false';
                } else {
                    let currentValue;
                    if (rhvCompany[key] && '' !== rhvCompany[key]) {
                        currentValue = rhvCompany[key];
                    }
                    result[key] = currentValue ?? rhvRegistrationFlow.companyContactInfo[key] ?? '';
                }
            }
            return result
        }
    )

