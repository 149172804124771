import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {UserEntity} from "../../schemas";
import {baseAPI} from "../../services/baseAPI";
import {
    getDossier,
    fetchDossiers,
    fetchDossierByEmail
} from "../dossier/dossierSlice";
import fileDownload from "js-file-download";
import {updateAssignment} from "../dossier/assignmentSlice";

const userAdapter = createEntityAdapter();

export const getUser = createAsyncThunk("users/getUser", async ({id}) => {
    if (id) {
        const results = await baseAPI.get(`/api/users/${id}`, {});
        const normalized = normalize(results.data, UserEntity);
        return normalized.entities;
    }
});

export const getUserByEmail = createAsyncThunk("users/getUserByEmail", async ({email}) => {
    if (email) {
        const results = await baseAPI.get(`/api/users/${email}/user-by-email`, {});
        const normalized = normalize(results.data, UserEntity);
        return normalized.entities;
    }
});

export const downloadUserProfile = createAsyncThunk("users/downloadUserProfile", async ({id}) => {
    const response = await baseAPI.download(`/api/users/${id}/download`, {});
    const filename = response.headers['file-name'] ?? 'unknown-file.zip';
    fileDownload(response.data, filename);

});

export const fetchAssigneeAutocompleteList = createAsyncThunk("users/fetchAssigneeAutocompleteList", async () => {
    const results = await baseAPI.get(`/api/users/assignee-autocomplete`);
    return results;
});

export const fetchRhvClientAutocompleteList = createAsyncThunk("users/fetchRhvClientAutocompleteList", async (params) => {
    const results = await baseAPI.get(`/api/users/rhv-client-autocomplete`, params);
    return results;
});


export const fetchAdminClientAutocompleteList = createAsyncThunk("users/fetchAdminClientAutocompleteList", async () => {
    const results = await baseAPI.get(`/api/users/admin-client-autocomplete`);
    return results;
});

export const sendForgottenPasswordLink = createAsyncThunk("users/sendForgottenPasswordLink", async (params, {rejectWithValue}) => {
    try {
        const {email} = params;
        const uri = `/api/public/users/${email}/send-login-link`;
        delete params['email'];

        const results = await baseAPI.get(uri, params);
        const normalized = normalize(results.data, UserEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getForgottenPasswordLink = createAsyncThunk("users/getForgottenPasswordLink", async (params, {rejectWithValue}) => {
    try {
        const {email} = params;
        const uri = `/api/users/${email}/send-login-link`;
        delete params['email'];

        const results = await baseAPI.get(uri, params);
        const normalized = normalize(results.data, UserEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const patchUser = createAsyncThunk("users/patchUser", async ({uri, formData}, {rejectWithValue}) => {
    try {
        const results = await baseAPI.patch(uri, formData);
        const normalized = normalize(results.data, UserEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const toggleAdminAvailability = createAsyncThunk("users/toggleAdminAvailability", async ({uri, formData}) => {
    try {
        await baseAPI.syncPatch(uri, formData);
    } catch (error) {
        // ignore failure
    }
});

export const createUser = createAsyncThunk("users/createUser", async ({formData}, {rejectWithValue}) => {
    try {
        const results = await baseAPI.post(`/api/public/users`, formData);
        const normalized = normalize(results.data, UserEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getNewCaseLink = createAsyncThunk("users/getNewCaseLink", async ({id}) => {
    const results = await baseAPI.get(`/api/users/${id}/new-case`);
    return results.data;
});

export const postNewCaseLink = createAsyncThunk("users/postNewCaseLink", async ({formData}, {rejectWithValue}) => {
    try {
        const results = await baseAPI.post(`/api/users/create-new-case`, formData);
        return results.data
    }catch (error) {
        return rejectWithValue({error: error.response.data, email: formData.email});
    }
});

const userSlice = createSlice({
    name: 'users',
    initialState: userAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(patchUser.fulfilled, (state, {payload}) => {
                userAdapter.upsertMany(state, payload.users);
            })
            .addCase(getDossier.fulfilled, (state, {payload}) => {
                userAdapter.upsertMany(state, payload.users ?? [])
            })
            .addCase(fetchDossiers.fulfilled, (state, {payload}) => {
                const users = Object.values(payload?.entities?.users ?? []);
                users.forEach(user => {
                    if (!state.ids.includes(user.id)) {
                        state.ids.push(user.id);
                        state.entities[user.id] = user;
                    }
                })
            })
            .addCase(fetchDossierByEmail.fulfilled, (state, {payload}) => {
                const users = Object.values(payload?.entities?.users ?? []);
                users.forEach(user => {
                    if (!state.ids.includes(user.id)) {
                        state.ids.push(user.id);
                        state.entities[user.id] = user;
                    }
                })
            })
            .addCase(getUser.fulfilled, (state, {payload}) => {
                /*const [user] = Object.values(payload.users);
                if(state.ids.includes(user.id)){
                    state.entities[user.id] = user;
                }//*/
                if (payload?.users) {
                    userAdapter.upsertMany(state, payload.users ?? []);
                }
            })
            .addCase(updateAssignment.fulfilled, (state, {payload}) => {
                if (payload?.action === 'updateClientPhone' && true === payload.phonePermission && payload?.phone && payload?.userId && state.ids.includes(payload.userId)) {
                    let  entity = state.entities[payload.userId];
                    entity.phone = payload?.phone;
                    state.entities[payload.userId] = entity ;
                }
            })
            .addCase(createUser.fulfilled, (state, {payload}) => {
                userAdapter.upsertMany(state, payload.users ?? []);
            })
    }
})

// export const {setToken, logOut} = dossierSlice.actions

export default userSlice.reducer

// Rename the exports for readability in component usage
export const {
    selectById: selectUserById,
    selectAll: selectAllUsers,
} = userAdapter.getSelectors(state => state.users)

export const getUserById = (id) =>
    createSelector(
        [
            state => state.users?.ids,
            state => state.users?.entities,
        ],
        (ids, entities) => {
            if (!entities) {
                return null;
            }
            return entities[id] ?? null;
        }
    )

