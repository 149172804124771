import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {combineReducers} from "redux";
import securityReducer from '../features/security/securitySlice'
import dossierReducer from '../features/dossier/dossierSlice'
import screenShareReducer from '../features/screenShare/screenShareSlice';
import userReducer from '../features/users/userSlice'
import assigneeReducer from '../features/users/assigneeSlice'
import assigmentReducer from '../features/dossier/assignmentSlice'
import messageReducer from '../features/dossier/messageSlice';
import treeReducer from '../features/tree/treeSlice'
import metadataReducer from '../features/metadata/metadataSlice'
import ruleReducer from '../features/metadata/ruleSlice'
import mediaObjectReducer from '../features/dossier/mediaObjectSlice'
import rhvCompanyReducer from '../features/rhv/RhvCompanySlice';
import rhvCompanyUserReducer from '../features/rhv/RhvCompanyUserSlice';
import rhvRegistrationFlowReducer from '../features/rhv/RhvRegistrationFlowSlice';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from "redux-persist";

const middleware = [...getDefaultMiddleware({
    serializableCheck: false
})];

const reducers = combineReducers(
    {
        security: securityReducer,
        dossiers: dossierReducer,
        assignments: assigmentReducer,
        screenShares: screenShareReducer,
        users: userReducer,
        assignees: assigneeReducer,
        messages: messageReducer,
        trees: treeReducer,
        metadata: metadataReducer,
        rules: ruleReducer,
        mediaObjects: mediaObjectReducer,
        rhvCompanies: rhvCompanyReducer,
        rhvCompanyUsers: rhvCompanyUserReducer,
        rhvRegistrationFlow: rhvRegistrationFlowReducer,
    }
);

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['rhvRegistrationFlow']
};

export const store = configureStore({
    reducer:  persistReducer(persistConfig, reducers),
    middleware,
});
