import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {generatePath} from "react-router";
import {doLogout, userHasRole} from "../../features/security/securitySlice";
import {ROLES} from "../../app/constants";
import {patchUser, toggleAdminAvailability} from "../../features/users/userSlice";
import LocalStorageService from "../../services/storage/LocalStorageService";
import AutoLogoutTimer from "./AutoLogoutTimer";

const LogoutTimerWrapper = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();
    const isAdministrator = useSelector(userHasRole(ROLES.ADMIN));
    const isAssigneeWorker = useSelector(userHasRole(ROLES.ASSIGNEE_WORKER));
    const isAssigneeAdmin = useSelector(userHasRole(ROLES.ASSIGNEE_ADMIN));
    const {token, isLoggedIn} = useSelector(state => state.security);


    // eslint-disable-next-line react-hooks/exhaustive-deps     
    const logUserOut = async () => {
        if(isAdministrator) {
            const formData = {
                isAvailable: false,
            };
            const uri = `/api/users/${token.id}`;
            await dispatch(patchUser({uri, formData}));
        }
        await dispatch(doLogout());
        push(generatePath('/'));
        LocalStorageService.clearAfterLogout();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const browserWasClosed = () => {
        if (isAdministrator) {
            const uri = `/api/users/${token.id}`;
            const formData = {
                isAvailable: false,
            };
            dispatch(toggleAdminAvailability({uri, formData}))
        }
    };

    useEffect(() => {
        if(isAdministrator || isAssigneeWorker || isAssigneeAdmin){
            const timer = new AutoLogoutTimer({
                /* eslint-disable-next-line */
                onTimeout: logUserOut,
                /* eslint-disable-next-line */
                onBrowserClosed: () => browserWasClosed()
            });

            return () => {
                timer.cleanUp();
            };
        }
        /* eslint-disable-next-line */
    }, [isAdministrator, isAssigneeWorker, isAssigneeAdmin, isLoggedIn, browserWasClosed, logUserOut]);

    return (<></>);
};
export default LogoutTimerWrapper;
