import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {RhvCompanyEntity} from "../../schemas";
import {baseAPI} from "../../services/baseAPI";
import {updateRhvCompanyUser, associateRhvCompanyUser, deleteRhvCompanyUser} from "./RhvCompanyUserSlice";
import {fetchMediaObject, uploadRhvCompanyProfileMedia} from "../dossier/mediaObjectSlice";
import {PAGINATION} from "../../app/constants";


const rhvCompanyAdapter = createEntityAdapter();

export const getRhvCompany = createAsyncThunk("rhvCompanies/getRhvCompany", async ({id}, {rejectWithValue}) => {
    if(!id){
        return;
    }
    try {
        const results = await baseAPI.get(`/api/rhv_companies/${id}`);
        const normalized = normalize(results.data, RhvCompanyEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const createRhvCompany = createAsyncThunk("rhvCompanies/createRhvCompany", async ({formData}, {rejectWithValue}) => {
    try {
        const results = await baseAPI.post('/api/public/rhv_companies', formData, [{
            name: 'content-type',
            value: 'application/ld+json'
        }]);
        const normalized = normalize(results.data, RhvCompanyEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


export const fetchRhvCompanyAutocompleteList = createAsyncThunk("rhvCompanies/fetchRhvCompanyAutocompleteList", async ({type = null, search = null}) => {
    const results = await baseAPI.get('/api/rhv_companies/autocomplete_list', {type, search, pagination: false});
    const normalized = normalize(results.data, RhvCompanyEntity);
    return normalized.entities;
});


export const fetchRhvCompanyFilterAutocompleteList = createAsyncThunk("rhvCompanies/fetchRhvCompanyFilterAutocompleteList", async ({legalAidType}) => {
    const results = await baseAPI.get('/api/rhv_companies/search_filter', {pagination: false, legalAidType});
    return results.data;
});

export const updateRhvCompanyAutoList = createAsyncThunk("rhvCompanies/updateRhvCompanyAutoList", async (params) => {
    const results = await baseAPI.get('/api/rhv_companies/filtered_company_list', params);
    const normalized = normalize(results.data['hydra:member'], [RhvCompanyEntity]);
    return normalized;
});

export const fetchRhvCompanies = createAsyncThunk("rhvCompanies/fetchRhvCompanies", async ({page = null, type = null, pagination=false}) => {
    let uri;
    switch (true) {
        case !!page && !!type:
            uri = `/api/rhv_companies?pagination=true&page=${page}&type=${type}&per_page=${PAGINATION.RHV_COMPANY}`;
            break;
        case !!page:
            uri = `/api/rhv_companies?pagination=true&page=${page}&per_page=${PAGINATION.RHV_COMPANY}`;
            break;
        case !!type && !!pagination:
            uri = `/api/rhv_companies?pagination=true&type=${type}`;
            break;
        case !!type :
            uri = `/api/rhv_companies?pagination=false&type=${type}`;
            break;
        default:
            uri = '/api/rhv_companies?pagination=false';
    }
    const results = await baseAPI.get(uri);
    const normalized = normalize(results.data['hydra:member'], [RhvCompanyEntity]);
    return {entities: normalized.entities, total: results.data['hydra:totalItems']};
});

export const updateRhvCompany = createAsyncThunk("rhvCompanies/updateRhvCompany", async ({id, formData}, {rejectWithValue}) => {
    if (!id) {
        return;
    }
    try {
        const results = await baseAPI.patch(`/api/rhv_companies/${id}`, formData, [{
            name: 'content-type',
            value: 'application/ld+json'
        }]);
        const normalized = normalize(results.data, RhvCompanyEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const rhvCompanySlice = createSlice({
    name: 'rhvCompanies',
    initialState: rhvCompanyAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createRhvCompany.fulfilled, (state, {payload}) => {
                rhvCompanyAdapter.upsertMany(state, payload.rhvCompanies);
            })
            .addCase(fetchRhvCompanies.fulfilled, (state, {payload}) => {
                state.ids = [];
                state.entities = {};
                rhvCompanyAdapter.upsertMany(state, payload?.entities?.rhvCompanies ?? []);
                state.total = payload?.total;
            })
            .addCase(updateRhvCompany.fulfilled,  (state, {payload}) => {
                rhvCompanyAdapter.upsertMany(state, payload.rhvCompanies);
            })
            .addCase(getRhvCompany.fulfilled, (state, {payload}) => {
                if (payload?.rhvCompanies) {
                    rhvCompanyAdapter.addMany(state, payload.rhvCompanies);
                }
            })
            .addCase(updateRhvCompanyUser.fulfilled, (state, {payload}) => {
                const [rhvCompanyUser] = Object.values(payload.rhvCompanyUsers);
                const companyId = rhvCompanyUser.employedBy.id;
                if (state.ids.includes(companyId)) {
                    state.entities[companyId].employees = state.entities[companyId].employees.map((employee) => {
                        if (employee?.id && rhvCompanyUser?.id && employee?.id === rhvCompanyUser.id) {
                            employee = rhvCompanyUser;
                        }
                        return employee;
                    })
                }
            })
            .addCase(deleteRhvCompanyUser.fulfilled, (state, {payload}) => {
                if(state.ids.includes(payload.rhvCompanyId)){
                    state.entities[payload.rhvCompanyId].employees = state.entities[payload.rhvCompanyId].employees.map((employee) => {
                        if (employee?.id && payload.deletedId && employee?.id === payload.deletedId) {
                            return false;
                        }
                        return employee;
                    })
                }
            })
            .addCase(fetchMediaObject.fulfilled, (state, {payload}) => {
                const [media] = Object.values(payload.mediaObjects);
                const entities = Object.values(state.entities);
                entities.forEach(entity => {
                    if (entity.photo?.id === media?.id) {
                        state.entities[entity.id].photo.contentUrl = media.contentUrl;
                    }
                });
            })
            .addCase(associateRhvCompanyUser.fulfilled, (state, {payload}) => {
                const [rhvCompanyUser] = Object.values(payload.rhvCompanyUsers);
                if (rhvCompanyUser?.employerFor && rhvCompanyUser?.employedBy) {
                    const companyId = rhvCompanyUser.employedBy.id;
                    if (state.ids.includes(companyId)) {
                        state.entities[companyId].employees.push(rhvCompanyUser);
                    }
                }
            })
            .addCase(uploadRhvCompanyProfileMedia.fulfilled, (state, {payload}) => {
                const [mediaObjects] = Object.values(payload.mediaObjects);
                if (mediaObjects?.rhvCompanyId && state.ids.includes(mediaObjects?.rhvCompanyId)) {
                    state.entities[mediaObjects.rhvCompanyId].photo = mediaObjects;
                }
            })
    }
});

export default rhvCompanySlice.reducer;


export const {
    selectAll: selectAllRhvCompanies,
    selectById: getRhvCompanyById,
} = rhvCompanyAdapter.getSelectors(state => state.rhvCompanies);

export const selectRhvCompanyById = id =>
    createSelector(
        [
            state => getRhvCompanyById(state, id),
            state => state.rhvRegistrationFlow.rhvCompanyId,
        ],
        (rhvCompany, rhvCompanyId) => {
            if (!rhvCompanyId && !rhvCompany) {
                return undefined
            }

            return rhvCompany
        }
    )
