import * as Yup from "yup";
import {DOSSIER, OFFICE, RHV} from "./app/constants";
import {removeInvisibleCharacters} from "./services/stringHelper";

export const ClientDataSchema = Yup.object().shape({
    name: Yup.string(),
    email: Yup.string().email('Vul een geldig e-mailadres in').required('Het invullen van een e-mailadres is verplicht'),
    birthday: Yup.string(),
    dateOfBirth: Yup.string(),
    address: Yup.string().trim().matches(/^([0-9]+[a-zA-Z]*[\s]+[a-zA-Z]+)|([a-zA-Z]+[\s]+[0-9]+)/, 'Geen geldige adres'),
    zipCode: Yup.string().trim().matches(/^[1-9][0-9]{3} *(?!SA|SD|SS)[A-Z]{2}$/, 'Geen geldige postcode'),
    city: Yup.string(),
    phone: Yup.string().matches(/^[ ()\-+0-9]+$/, 'Het telefoonnummer bevat ongeldige tekens')
        .min(10, 'Het telefoonnummer moet minimaal 10 tekens')
})

export const getTranslatedError = (field, message) => {
    if ('This value is already used.' === message && 'email' === field) {
        return 'Het e-mailadres is al in gebruik';
    }
    return message;
};

export const ClientPhoneSchema = Yup.object().shape({
    phone: Yup.string().matches(/^[ ()\-+0-9]+$/, 'Het telefoonnummer bevat ongeldige tekens')
        .required('Het invullen van een telefoonnummer is verplicht'),
});

/**
 * OneOf doesn't seem to work this fixes it
 */
Yup.addMethod(Yup.string, "fixOneOf", function (values, errorMessage) {
    return this.test(`fix-one-of`, errorMessage, function (value) {
        return values.includes(value);
    });
});

/**
 * OneOf and notOneOf don't seem to work ????
 */
Yup.addMethod(Yup.string, "fixNotOneOf", function (values, errorMessage) {
    return this.test(`fix-not-one-of`, errorMessage, function (value) {
        return !values.includes(value);
    });
});

export const getRhvRegistrationContactInfoSchema = (usedWebsites, subsidyValues = []) => {
    return Yup.object().shape({
        name: Yup.string().required('Verplicht'),
        officeSize: Yup.string().fixOneOf(OFFICE.ACCEPTABLE_SIZES, `Organisatie groote verplicht`),
        address: Yup.string().trim().matches(/^([0-9]+[a-zA-Z]*[\s]+[a-zA-Z]+)|([a-zA-Z]+[\s]+[0-9]+)/, 'Geen geldige adres').required('Het invullen van de adres is verplicht'),
        zipCode: Yup.string().trim().matches(/^[1-9][0-9]{3} *(?!SA|SD|SS)[A-Z]{2}$/, 'Geen geldige postcode').required('Het invullen van de postcode is verplicht'),
        city: Yup.string().required('Het invullen van de plaatsnaam is verplicht'),
        phone: Yup.string().matches(/^[ ()\-+0-9]+$/, 'Het telefoonnummer bevat ongeldige tekens')
            .min(10, 'Het telefoonnummer moet uit 10 cijfers bestaan.')
            .required('Het invullen van de telefoon is verplicht'),
        website: Yup.string().matches(
            /^$|http(s)?:\/\/(www.)?[a-z0-9\-.]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Op deze wijze: http://www.ditformaatisjuist.nl'
        ),
        type: Yup.string().fixOneOf(OFFICE.ACCEPTABLE_VALUES, 'no error message needed or used'),
    });
};
export const RhvOptOutSchema = Yup.object().shape({

    reasonForOptingOut: Yup.string().required('Selecteer de reden').nullable(),
    otherReasonForOptingOut: Yup.string().when('reasonForOptingOut', {
        is: 'OTHER',
        then: Yup.string().required('Vul de reden in').nullable(),
    }).nullable(),
    explanation: Yup.string().required('Verplicht').nullable(),
});

/**
 * OneOf doesn't seem to work this fixes it
 */
Yup.addMethod(Yup.mixed, "ensureAreasOfLaw", function (errorMessage) {

    let requiredValues = [];
    for (const [key, value] of Object.entries(RHV.AREA_TO_CATEGORY)) {
        if ('all' === value.type) {
            requiredValues.push(key);
        }
    }
    return this.test(`ensureAreasOfLaw`, errorMessage, function (values) {
        let chosenAreasOfLaw = 0;
        for (const [key, value] of Object.entries(values ?? {})) {
            if (value && requiredValues.includes(key)) {
                chosenAreasOfLaw++;
            }
        }
        return chosenAreasOfLaw > 0;
    });
});

export const getRhvStartRegistrationSchema = (usedEmails) => {
    return Yup.object().shape({
        officeType: Yup.string().fixOneOf(OFFICE.ACCEPTABLE_VALUES, `U moet type kiezen `),
        officeName: Yup.string().required('Het invullen van de organisatienaam is verplicht'),
        adminName: Yup.string().required('Het invullen van de naam van de beheerder is verplicht'),
        adminEmail: Yup.string()
            .email('Vul een geldig e-mailadres in')
            .fixNotOneOf(usedEmails, 'Het e-mailadres is al in gebruik')
            .required('Het invullen van een e-mailadres is verplicht'),
        subscriptionPrice: Yup.number().required("Verplicht").integer(),
        acceptedSubscriptionTerms: Yup.mixed().oneOf([true, 'true'], 'Verplicht.').required("Verplicht"),
        //officeSize: Yup.string().fixOneOf(OFFICE.ACCEPTABLE_SIZES, `Organisatie groote verplicht`),
        address: Yup.string().trim().matches(/^([0-9]+[a-zA-Z]*[\s]+[a-zA-Z]+)|([a-zA-Z]+[\s]+[0-9]+)/, 'Geen geldige adres').required('Het invullen van de adres is verplicht'),
        zipCode: Yup.string().trim().matches(/^[1-9][0-9]{3} *(?!SA|SD|SS)[A-Z]{2}$/, 'Geen geldige postcode').required('Het invullen van de postcode is verplicht'),
        city: Yup.string().required('Het invullen van de plaatsnaam is verplicht'),
        phone: Yup.string().matches(/^[ ()\-+0-9]+$/, 'Het telefoonnummer bevat ongeldige tekens')
            .min(10, 'Het telefoonnummer moet uit 10 cijfers bestaan.')
            .required('Het invullen van de telefoon is verplicht'),
        website: Yup.string().matches(
            /^$|http(s)?:\/\/(www.)?[a-z0-9\-.]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Op deze wijze: http://www.ditformaatisjuist.nl'
        ).required("Verplicht"),
        areas: Yup.mixed().ensureAreasOfLaw(`U moet tenminste eem rechtsgebied kiezen `)
    });
};

    export const getAnonymousUserEmailOnlySchema = (usedEmails) => {
    return Yup.object().shape({
        email: Yup.string()
            .email('Vul een geldig e-mailadres in')
            .fixNotOneOf(usedEmails, 'Het e-mailadres is al in gebruik')
            .required('Het invullen van een e-mailadres is verplicht')
    });
};

export const RhvFilterByClientSchema = Yup.object().shape({
    postcode: Yup
        .string()
        .trim()
        .matches(/^[1-9][0-9]{3} *[a-zA-Z]{2}$/, 'Voer een geldige postcode in.')
        .required('Voer een geldige postcode in.'),
    distance: Yup.mixed().oneOf(['0', '10', '25', '50', '100'], 'no error message needed or used'),
});

export const RhvRegistrationOfficeLayoutSchema = Yup.object().shape({
    name: Yup.string().required('Naam is verplicht'),
    email: Yup.string()
        .email('Vul een geldig e-mailadres in')
        .required('Het invullen van een e-mailadres is verplicht'),
});

export const getLoginSchema = (usedEmails) => {
    return Yup.object().shape({
        email: Yup.string()
            .email('Vul een geldig e-mailadres in')
            .fixNotOneOf(usedEmails, 'Het e-mailadres is al in gebruik')
            .required('Het invullen van een e-mailadres is verplicht'),
        password: Yup.string().required('Wachtwoord verplicht')
    });
};

/**
 * notEmptyString
 */
Yup.addMethod(Yup.mixed, "notEmptyString", function (errorMessage) {
    return this.test(`not-empty-string`, errorMessage, function (value) {
        let replaced = removeInvisibleCharacters(value).replace(/(\r\n|\n|\r|\s)/gm, "");
        return replaced !== '';
    });
});

/**
 * notEmptyMarkdown
 */
Yup.addMethod(Yup.mixed, "notEmptyMarkdown", function (errorMessage) {
    return this.test(`not-empty-markdown`, errorMessage, function (value) {
        const markdown = value ? value.toString('markdown') : '';
        let replaced = removeInvisibleCharacters(markdown).replace(/(\r\n|\n|\r|\s)/gm, "");
        return replaced !== '';
    });
});

let  acceptableCategories = [...DOSSIER.SIMPLE_CATEGORIES];
acceptableCategories.shift();
export const getMakeBespokeDossierDialogSchema =  (usedEmails) => {
    return Yup.object().shape({
        name: Yup.string().required('Verplicht'),
        email: Yup.string().email('Vul een geldig e-mailadres in')
            .fixNotOneOf(usedEmails, 'Het e-mailadres is al in gebruik.'),
        address: Yup.string().trim().matches(/^([0-9]+[a-zA-Z]*[\s]+[a-zA-Z]+)|([a-zA-Z]+[\s]+[0-9]+)/, 'Geen geldige adres'),
        zipCode: Yup.string().trim().matches(/^[1-9][0-9]{3} *(?!SA|SD|SS)[A-Z]{2}$/, 'Geen geldige postcode'),
        city: Yup.string(),
        category: Yup.mixed().oneOf(acceptableCategories, 'Verplicht.').required('Verplicht'),
        summary: Yup.mixed().notEmptyMarkdown('Verplicht').required('Verplicht'),
        solution: Yup.mixed().notEmptyMarkdown('Verplicht').required('Verplicht'),
        notation: Yup.mixed(),
        shareDossierWithClient: Yup.mixed()
    });
}

/**
 * ensure that text cannot be split into too many parts
 */
Yup.addMethod(Yup.mixed, "maxSplitText", function (total, errorMessage) {
    return this.test(`max-split-text`, errorMessage, function (value) {
        value = value ?? '';
        const split = value.split(',');
        return split.length <= total;
    });
});


export const RhvCompanyProfileSchema = Yup.object().shape({
    profileText: Yup.mixed().notEmptyMarkdown('Profieltekst verplicht').required('Profieltekst verplicht'),
    photo: Yup.mixed().required('Foto verplicht'),
    //qualities: Yup.mixed().required('Tenminste een kenmerk verplicht'),
    not_on_offer: Yup.mixed().notEmptyMarkdown('Verplicht').required('Verplicht'),
    company_conditions: Yup.mixed().notEmptyMarkdown('Verplicht').required('Verplicht'),
   // languages: Yup.mixed().notEmptyMarkdown('Verplicht').required('Verplicht'),
});


export const RhvCompanyTermsSchema = Yup.object().shape({
    not_on_offer: Yup.mixed().notEmptyMarkdown('Verplicht').required('Verplicht'),
    company_conditions: Yup.mixed().notEmptyMarkdown('Verplicht').required('Verplicht'),
    languages: Yup.mixed().notEmptyMarkdown('Verplicht').required('Verplicht'),
});


export const ClientNewLinkSchema = Yup.object().shape({
    email: Yup.string()
        .email('Vul een geldig e-mailadres in')
        .required('Het invullen van een e-mailadres is verplicht')
});
export const AssigneeNoteValidationSchema = Yup.object().shape({
    notation: Yup.string().required('verplicht')
});

export const PermissionToShareClientCaseSchema = Yup.object().shape({
    sharePermission: Yup.bool().oneOf([true], 'Geef toestemming om verder te gaan'),
    phonePermission: Yup.bool().oneOf([true, false], ''),
    phone: Yup
        .string()
        .matches(/^[ ()\-+0-9]+$/, 'Het telefoonnummer bevat ongeldige tekens')
        .min(10, 'Het telefoonnummer moet minimaal 10 tekens')
        .when('phonePermission', {
            is: true,
            then: Yup.string().required('Voer uw telefoonnummer in om deze te delen'),
        })

});
export const RhvForgottenPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Vul een geldig e-mailadres in')
        .required('Het invullen van een e-mailadres is verplicht')
});
/* eslint-disable-next-line */
const specialCharacters = /[@#$%&*<>^\!()\-+=*:;\/|{}\[\]±~§\\"'.,?_]/;

export const specialCharacterList = specialCharacters;

/**
 * passwordMatches
 */
Yup.addMethod(Yup.string, "passwordMatches", function (ref, msg) {
    return this.test({
        name: 'passwordMatches',
        exclusive: false,
        message: msg,
        params: {
            reference: ref.path
        },
        test: function (value) {
            return value === this.resolve(ref)
        }
    })
});

Yup.addMethod(Yup.string, "passwordConforms", function (msg) {
    return this.test({
        name: 'passwordConforms',
        exclusive: false,
        message: msg,
        test: function (password) {
            return /[A-Z]/.test(password) && /[A-Za-z]{5}/.test(password) && specialCharacters.test(password);
        }
    })
});

export const ClientDeleteProfileSchema = Yup.object().shape({
    deleteProfile: Yup.bool().oneOf([true], 'Verplicht'),
});

export const ClientRestoreProfileSchema = Yup.object().shape({
    restoreProfile: Yup.bool().oneOf([true], 'Verplicht'),
});

export const ClientDeleteDossierSchema = Yup.object().shape({
    deleteConfirmed: Yup.bool().oneOf([true], 'Verplicht'),
});

export const ClientRestoreDossierSchema = Yup.object().shape({
    restoreConfirmed: Yup.bool().oneOf([true], 'Verplicht'),
});

export const ClientCancelAssignmentSchema = Yup.object().shape({
    cancelAssignmentConfirm: Yup.bool().oneOf([true], 'Verplicht'),
});

export const ChangeAssignmentPhonePermissionSchema = Yup.object().shape({
    changeConfirmed: Yup.bool().oneOf([true], 'Verplicht'),
    phone: Yup.string().when('changeType', {
            is: false,
            then: Yup.string().matches(/^[ ()\-+0-9]+$/, 'Het telefoonnummer bevat ongeldige tekens')
                .required('Het invullen van een telefoonnummer is verplicht')
                .min(10, 'Het telefoonnummer moet minimaal 10 tekens')
        }
    )


});

export const RhvSetPasswordSchema = Yup.object().shape({
    password: Yup.string().passwordConforms('Wachtwoord voldoet niet aan de eisen').required('Wachtwoord is verplicht'),
    repeatPassword: Yup.string().passwordMatches(Yup.ref('password'), 'Tweede wachtwoord is niet gelijk aan uw eerste wachtwoord')
});

export const getRhvSetPasswordSchema = (oldPassword)=> {

    let shapes = {
        password: Yup.string().passwordConforms('Wachtwoord voldoet niet aan de eisen').required('Wachtwoord is verplicht'),
        repeatPassword: Yup.string().passwordMatches(Yup.ref('password'), 'Tweede wachtwoord is niet gelijk aan uw eerste wachtwoord')
    };
    if (oldPassword) {
        shapes.oldPassword = Yup.string().required('Oude wachtwoord is verplicht');
    }
    return Yup.object().shape(shapes);
}

export const translateBackendError = (errorMessage, defaultValue = null) => {
    let translated = defaultValue ?? errorMessage;
    switch (errorMessage) {
        case 'Invalid credentials.':
            return 'Onbekende combinatie van email en wachtwoord.';
        case 'Old password is invalid':
            return 'Ongeldige wachtwoord';
        case "Cannot read properties of undefined (reading 'data')":
            return 'Probleem! Over een paar minuten opnieuw proberen.';
        default:
            console.log('errorMessage', errorMessage);
    }
    return translated;
};


export const validateGeocodeResponse = (addressComponents, values) => {
    const searched = ["street_number", "route", "postal_code", "locality"];

    function getParts() {
        let parts = {
            "street_number": '',
            "route": '',
            "postal_code": '',
            "locality": '',
        };
        addressComponents.forEach((component) => {
            for (let type of searched) {
                if (component.types.includes(type)) {
                    parts[type] = component.long_name;
                }
            }
        });
        return parts;
    }

    const geocodeParts = getParts();
    return {
        address: (`${geocodeParts['route']}${geocodeParts['street_number']}`).replace(/\s/g, '').toLowerCase() !== (values.address).replace(/\s/g, '').toLowerCase(),
        postcode: (geocodeParts['postal_code']).replace(/\s/g, '').toLowerCase() !== (values.zipCode).replace(/\s/g, '').toLowerCase(),
        city: (geocodeParts['locality']).replace(/\s/g, '').toLowerCase() !== (values.city).replace(/\s/g, '').toLowerCase()
    };
};

export const getRhvCreateNewClientSchema = (usedEmails) => {
    return Yup.object().shape({
        email: Yup.string()
            .email('Vul een geldig e-mailadres in.')
            .fixNotOneOf(usedEmails, 'Het e-mailadres is al in gebruik.')
            .when('name', {
                is: (val) => {
                    return (!val || val === '');
                },
                then: Yup.string().required('Invullen naam of e-mail rechtzoekende is verplicht.')
            }),
        name: Yup.string().trim().when('email', {
            is: (val) => {
                return (!val || val === '');
            },
            then: Yup.string().required('Invullen naam of e-mail rechtzoekende is verplicht.')
        }),
        acceptedShare: Yup.mixed().oneOf([true, 'true'], 'Verplicht.')

    }, ['email', 'name', 'acceptedShare']);
};

export const RhvShareDossierInternallySchema = Yup.object().shape({
    employeeId: Yup.string().required(' Selecteer medewerker').nullable(),
});

export const RhvTransferDossierInternallySchema = Yup.object().shape({
    employeeId: Yup.string().required('Selecteer medewerker').nullable(),
    reasonForTransfer: Yup.string().required('Selecteer de reden').nullable(),
    otherReasonForTransfer: Yup.string().when('reasonForTransfer', {
        is: 'OTHER',
        then: Yup.string().required('Vul de reden in').nullable(),
    }).nullable(),
    messageToClient: Yup.string().nullable(),
});
export const RhvTransferDossierExternallySchema = Yup.object().shape({
    messageToClient: Yup.string().nullable(),
    messageToRhv: Yup.string().nullable(),
    agreedWithClient: Yup.mixed().oneOf([true, 'true'], 'Verplicht.'),
    agreedWithRhv: Yup.mixed().oneOf([true, 'true'], 'Verplicht.'),
    newRhvEmail: Yup.string()
        .email('Vul een geldig e-mailadres in.').required('Verplicht.')
});

export const RhvStopClientHelpSchema = Yup.object().shape({
    reasonForStopping: Yup.string().trim().required('Selecteer de reden').nullable(),
    otherReasonForStopping: Yup.string().when('reasonForStopping', {
        is: 'OTHER',
        then: Yup.string().trim().required('Vul de reden in').nullable(),
    }).nullable(),
    messageToClient: Yup.string().nullable(),
});
