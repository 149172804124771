import React, {useState} from "react";
import {Field} from "formik";
import ShowPasswordWidget from "../widget/ShowPasswordWidget";


const GiLawPasswordFieldWrapper = ({fieldName, divClass, fieldClass, touched, errors, placeholder, toggle = true, onFocus = null , onBlur = null}) => {

    const [showPassword, setShowPassword] = useState('password');
    const checkBlur = (evt) => {
        if (onBlur) {
            onBlur(evt)
        }

    }
    const checkFocus = (evt) => {
        if (onFocus) {
            onFocus(evt)
        }
    }
    return (
        <>
            <div data-component={`GiLawPasswordFieldWrapper`} className={`${divClass} ${errors[fieldName] && touched[fieldName] ? 'error-field-wrapper' : ''}`}>
                <Field
                    name={fieldName}
                    id={fieldName}
                    className={`${fieldClass} ${errors[fieldName] && touched[fieldName] ? 'form-field-with-errors' : ''}`}
                    type={showPassword}
                    placeholder={placeholder}
                    autoComplete="on"
                    onBlur={checkBlur}
                    onFocus={checkFocus}
                />
                {toggle && <ShowPasswordWidget showing={showPassword} setShowing={setShowPassword} />}
            </div>
        </>
    );
};
export default GiLawPasswordFieldWrapper;
