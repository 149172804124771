import React, {useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Alert, Snackbar} from "@mui/material";

const CssPathIdentifier = ({parts, isVisible}) => {
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    if (!parts || !isVisible ) {
        return (<></>);
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCopiedToClipboard(false);
    };
    const lastPart = parts && parts.length > 0 ? parts[parts.length -1] : 'nothing';

    return (
        <>
            <CopyToClipboard text={parts.reverse().join("\n")}
                             onCopy={() => setCopiedToClipboard(true)}>
                <div className={`copy-path-to-clipboard-wrapper`}  >
                <p className={`copy-path-to-clipboard`}  >{lastPart} </p>
                </div>
            </CopyToClipboard>
            <Snackbar
            style={{marginTop: '20px'}}
            open={copiedToClipboard}
            autoHideDuration={2500}
            onClose={handleSnackbarClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={`must-continue-snackbar-alert`}
        >
            <Alert severity="info">
                path copied to clipboard
            </Alert>
        </Snackbar>
        </>
    );
};

export default CssPathIdentifier;
