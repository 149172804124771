const LocalStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }

  function _setToken(tokenObj) {
    const refreshToken = tokenObj.refreshToken || tokenObj.refresh_token;
    localStorage.setItem('access_token', tokenObj.token);
    localStorage.setItem('refresh_token', refreshToken);
  }

  function _getAccessToken() {
    return localStorage.getItem('access_token');
  }

  function _getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  function _clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  function _get(key) {
    return localStorage.getItem(key);
  }
  function _set(key, value) {
    localStorage.setItem(key, value);
  }
  function _clear(key) {
    localStorage.removeItem(key);
  }

  function _clearAll() {
    localStorage.clear();
  }

  function _clearAfterLogout() {
    localStorage.clear();
    localStorage.setItem('noMessage', 'true');
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    get: _get,
    set: _set,
    clear:_clear,
    clearAll:_clearAll,
    clearAfterLogout: _clearAfterLogout
  }
})();

export default LocalStorageService
