import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {messagesEntity} from "../../schemas";
import {baseAPI} from "../../services/baseAPI";
import {uploadMessageFile} from "./mediaObjectSlice";


const messageAdapter = createEntityAdapter();

export const fetchMessageThread = createAsyncThunk("messages/fetchMessageThread", async ({id}) => {
        const results = await baseAPI.get(`/api/client_case_messages/${id}/thread`);
        const normalized = normalize(results.data['hydra:member'], [messagesEntity]);
        return normalized.entities;
    }
);

export const postMessage = createAsyncThunk("messages/postMessage", async ({formData}) => {
        const results = await baseAPI.post(`/api/client_case_messages`, formData, [{
            name: 'content-type',
            value: 'application/ld+json'
        }]);

        const normalized = normalize(results.data, messagesEntity);
        return normalized.entities;
    }
);
export const updateUnreadMessages = createAsyncThunk("messages/updateUnreadMessages", async ({formData}) => {
    await baseAPI.post(`/api/client_case_messages/update_unread_messages`, formData, [{
        name: 'content-type',
        value: 'application/ld+json'
    }]);
    return formData;
});

const messageSlice = createSlice({
    name: 'messages',
    initialState: messageAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMessageThread.fulfilled,  (state, {payload}) => {
                messageAdapter.upsertMany(state, payload.message)
            })
            .addCase(postMessage.fulfilled, (state, {payload}) => {
                messageAdapter.upsertMany(state, payload.message)
            })
            .addCase(uploadMessageFile.fulfilled, (state, {payload}) => {
                const [mediaObject] = Object.values(payload.mediaObjects);
                Object.values(state.entities).forEach((entity) => {
                    if(entity['@id'] === mediaObject.message){
                        entity.attachments.push(mediaObject);
                    }
                });
            })
            .addCase(updateUnreadMessages.fulfilled, (state, {payload}) => {
                if (Array.isArray(payload.messageIds )){
                    const {ids, entities} = state;
                    payload.messageIds.forEach((messageId) =>{
                        if (ids.includes(messageId) && payload.readTime) {
                            if(payload.isAssignee){
                                entities[messageId].assigneeFirstRead = payload.readTime;
                            } else {
                                entities[messageId].clientFirstRead = payload.readTime;
                            }
                        }
                    });
                }
            })
    }
});

export default messageSlice.reducer;


export const getMessagesByQuestionId = id =>
    createSelector(
        [
            state => state.messages.entities
        ],
        (messages) => {
            return messages.filter(message => message.questionId === id)
        }
    )
