import React, {useState} from "react";
import {Field} from "formik";


const GiLawTextFieldWrapper = (
    {
        fieldName,
        errors,
        touched,
        placeholder,
        divClass,
        fieldClass,
        type = `text`,
        readOnly = false,
        setFieldTouched = null,
        onBlur = null,
        onFocus = null,
        onKeyDown = null,
        showValue = true,
        shownValue = '',
        disabled = false,
        autoShowErrors = false,
        values
    }) => {
    const currentValue = !values || !values.hasOwnProperty(fieldName) ? '' : values[fieldName];

    const valueIsEmpty = currentValue === '' ;
    const [fieldFocusClass, setFieldFocusClass] = useState('');

    const checkAutofill = () => {
        if (setFieldTouched && !autoShowErrors) {
            setFieldTouched(fieldName, true, true);
        }
    };
    const checkBlur = (evt) => {
        setFieldFocusClass('');
        if (onBlur) {
            onBlur(evt);
        } else if (autoShowErrors) {
            setFieldTouched(fieldName, true, true)
        }
    };

    const checkOnFocus = (evt) => {
        setFieldFocusClass('field-has-focus');
        if (onFocus) {
            onFocus(evt);
        }else if (autoShowErrors) {
            setFieldTouched(fieldName, false, false);
        }
    }
    const checkKeyDown = (evt) => {
        if (onKeyDown) {
            onKeyDown(evt);
        }
    }
    return (
        <>
            <div data-component={`GiLawTextFieldWrapper`}
                 className={`${divClass} ${errors[fieldName] && touched[fieldName] ? 'error-field-wrapper' : ''} ${fieldFocusClass}`}>
                <Field
                    name={fieldName}
                    id={fieldName}
                    className={`${valueIsEmpty ? 'empty-input-field' : ''} ${fieldClass} ${errors[fieldName] && touched[fieldName] ? 'form-field-with-errors' : ''}`}
                    type={type}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    onInput={checkAutofill}
                    onBlur={(evt) => checkBlur(evt)}
                    onFocus={(evt)=>checkOnFocus(evt)}
                    disabled={disabled}
                    value={showValue ? currentValue : shownValue}
                    onKeyDown={checkKeyDown}
                />
            </div>
        </>
    );
};

export default GiLawTextFieldWrapper;
