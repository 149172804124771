import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {normalize} from "normalizr";
import {RhvCompanyUserEntity} from "../../schemas";
import {baseAPI} from "../../services/baseAPI";
import {fetchAssignees} from "../users/assigneeSlice";

const rhvCompanyUserAdapter = createEntityAdapter();

export const associateRhvCompanyUser = createAsyncThunk("rhvCompanyUsers/associateRhvCompanyUser", async ({formData}, {rejectWithValue}) => {
    try {
        const results = await baseAPI.post('/api/public/rhv_company_users', formData, [{
            name: 'content-type',
            value: 'application/ld+json'
        }]);
        const normalized = normalize(results.data, RhvCompanyUserEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


export const updateRhvCompanyUser = createAsyncThunk("rhvCompanyUsers/updateRhvCompanyUser", async ({id, formData}, {rejectWithValue}) => {
    try {
        const results = await baseAPI.patch(`/api/rhv_company_users/${id}`, formData, [{
            name: 'content-type',
            value: 'application/ld+json'
        }]);
        const normalized = normalize(results.data, RhvCompanyUserEntity);
        return normalized.entities;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteRhvCompanyUser = createAsyncThunk("rhvCompanyUsers/deleteRhvCompanyUser", async ({id, rhvCompanyId}, {rejectWithValue}) => {
    try {
        await baseAPI.delete(`/api/rhv_company_users/${id}`);
        return {deletedId: id, rhvCompanyId};
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


const rhvCompanyUserInitialState = {
    associations: rhvCompanyUserAdapter.getInitialState(),
    forms: {
        firstForm: {
            officeName: null,
            adminName: null,
            adminEmail: null,
            officeType: null,
        }
    }
};

const rhvCompanyUserSlice = createSlice({
    name: 'rhvCompanyUsers',
    initialState: rhvCompanyUserInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(associateRhvCompanyUser.fulfilled, (state, {payload}) => {
                rhvCompanyUserAdapter.addMany(state.associations, payload.rhvCompanyUsers);
                const [association] = Object.values(payload.rhvCompanyUsers);
                state.forms.firstForm.officeName = association.employedBy.name;
                state.forms.firstForm.adminName = association.employerFor.name;
                state.forms.firstForm.adminEmail = association.employerFor.email;
                state.forms.firstForm.officeType = association.employedBy.type;
            })
            .addCase(updateRhvCompanyUser.fulfilled, (state, {payload}) => {
                rhvCompanyUserAdapter.addMany(state.associations, payload.rhvCompanyUsers);
            })
            .addCase(deleteRhvCompanyUser.fulfilled, (state, {payload}) => {
                if(state.associations.ids.includes(payload.deletedId)){
                    delete state.associations[payload.deletedId];
                }
                //rhvCompanyUserAdapter.addMany(state.associations, payload.rhvCompanyUsers);
            })
            .addCase(fetchAssignees.fulfilled, (state, {payload}) => {
                const users = Object.values(payload.users);
                const companyUsers = users.map((user)=>{
                    return user.employers[0]
                }).filter((item)=>{
                    return !!item
                });
                rhvCompanyUserAdapter.upsertMany(state.associations, companyUsers);
            })
    }
});


export default rhvCompanyUserSlice.reducer;

export const {
    selectAll: selectAllRhvCompanyUsers,
} = rhvCompanyUserAdapter.getSelectors(state => state.rhvCompanyUsers);
