import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Login from "./Login";
import {handleMagicLinkLogin, handleTokenRefresh} from "../../features/security/securitySlice";
import LocalStorageService from "../../services/storage/LocalStorageService";
//import {goToMainWebsiteLoginPage} from "../../services/urlHelper";

function Authentication(props) {
    const {isLoggedIn} = useSelector(state => state.security);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const refreshToken = LocalStorageService.getRefreshToken()

    const query = new URLSearchParams(window.location.search)
    const auth = query.get('auth')
    const clientCase = query.get('client_case');
    const openPaths = ['/rhv-registration', '/new-link'];

    const handleUnsuccessfulRestore = (data) => {
        if (!data) {
            return;
        }
        const isClient = data?.isClient ?? null;
        const email = data?.email ?? null;
        const emailParam = email ? `&email=${encodeURIComponent(email)}` : ``;
        switch (isClient) {
            case true:
                window.location.assign(`/new-link?message_key=expired_client${emailParam}`);
                break;
            case false:
                window.location.assign(`/?message_key=expired_rhv${emailParam}`);
                break;
            default:
                // @todo is entry only via main website??
                //goToMainWebsiteLoginPage();
        }
    };

    useEffect(() => {
        if (!isLoggedIn && refreshToken !== null) {
            dispatch(handleTokenRefresh()).then(() => {
                setLoading(false)
            })
        }
    }, [dispatch, isLoggedIn, refreshToken])

    useEffect(()=>{
       if (!isLoggedIn && auth) {
           dispatch(handleMagicLinkLogin({auth, clientCase})).then((response) => {
               if ('security/handleMagicLinkLogin/rejected' === response.type ) {
                   handleUnsuccessfulRestore(response?.payload);
               } else if(response?.payload?.data?.failed === true) {
                   handleUnsuccessfulRestore(response?.payload?.data);
               }
           });
       }
    }, [dispatch, isLoggedIn, auth, clientCase])

    if (!isLoggedIn && loading && refreshToken !== null) {
        return <>loading auth</>
    }

    if (!isLoggedIn && auth) {
        return <>redirecting...</>
    }

    if (!isLoggedIn && !openPaths.includes(window.location.pathname)) {
        return <Login />
    }

    return props.children
}

export default Authentication
