import React from "react";

const ClearLocalStorage = () => {
    const clearStorage = () => {
        localStorage.clear();
        window.location.reload();
    };
    return (
        <>
            <div className="clear-local-storage-outer" data-component="ClearLocalStorage">
                <div className="clear-local-storage-wrapper">
                    <button onClick={clearStorage}>Clear Local Storage</button>
                </div>
            </div>
        </>
    );
};
export default ClearLocalStorage;
