import {refreshAccessToken} from "../features/security/securitySlice";
import axios from "axios";
import LocalStorageService from "./storage/LocalStorageService";
import env from "@beam-australia/react-env";

const API_BASE_URL = env('API_BASE_URL')
const TREE_MAKER_API_BASE_URL = env('TREE_MAKER_API_BASE_URL')

export const baseAPI = {
  get(uri, params = {}) {
    return axios.get(API_BASE_URL + uri, {params});
  },
  post(uri, formData, headers = []) {
    try {
      const config = {
        headers: {}
      };
      headers.forEach((header) => {
        config.headers[header.name] = header.value;
      });
      return axios.post(API_BASE_URL + uri, formData, config);
    } catch (err) {
      console.log('post error',err)
    }
  },
  patch(uri, formData) {
    return axios.patch(API_BASE_URL + uri, formData);
  },
  syncPatch(uri, formData) {
    const token = LocalStorageService.getAccessToken();
    const config = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(formData),
      keepalive: true,
    };
    return fetch(API_BASE_URL + uri, config);
  },
  put(uri, formData) {
    return axios.put(API_BASE_URL + uri, formData);
  },
  delete(uri) {
    return axios.delete(API_BASE_URL + uri);
  },
  download(uri, params = {}){
    return axios.request({
      url: API_BASE_URL + uri,
      method:'POST',
      responseType: 'blob', //important
      data: params
    })
  }
};

export const treeAPI = {
  get(uri, params = {}) {
    return axios.get(TREE_MAKER_API_BASE_URL + uri, {params});
  },
  post(uri, formData) {
    return axios.post(TREE_MAKER_API_BASE_URL + uri, formData);
  },
  patch(uri, formData) {
    return axios.patch(TREE_MAKER_API_BASE_URL + uri, formData);
  },
  put(uri, formData) {
    return axios.put(TREE_MAKER_API_BASE_URL + uri, formData);
  },
  delete(uri) {
    return axios.delete(TREE_MAKER_API_BASE_URL + uri);
  },
};

// Add Bearer token
axios.interceptors.request.use(
  config => {
    const token = LocalStorageService.getAccessToken()

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
  },
  error => {
    Promise.reject(error)
  });

// Refresh token if expired
axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config;

  console.log('API error: ' + error)

  // Show error to user
  if ((error?.response?.status === 400 || error?.response?.status === 500) && !originalRequest._retry) {
    console.log('Api error!')
  }

  if (error?.response?.status === 401 && !originalRequest._retry) {
    if (error.response?.data?.message === 'Expired JWT Token') {
      originalRequest._retry = true;

      return refreshAccessToken().then(data => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;

        return axios(originalRequest);
      })
    }
  }

  return Promise.reject(error);
});
