import {baseAPI} from "./baseAPI";

const screenShareAPI = {
    list(screenPage = null) {
        const uri = screenPage ? `/api/screen_shares?pagination=true&page=${screenPage}` :'/api/screen_shares';
        return baseAPI.get(uri)
    },
    getAdminScreenShareAutocomplete(){
        return baseAPI.get(`/api/screen_shares/admin_screen_share_autocomplete_list`);
    },
    get(id) {
        return baseAPI.get(`/api/public/screen_shares/${id}`);
    },
    patch(id, formData) {
        return baseAPI.patch(`/api/public/screen_shares/${id}`, formData);
    }
};

export default screenShareAPI;
